import React, { useState } from 'react'
import { Container, Row } from '@kogk/common'
import cn from 'classnames'
import styles from './MobileFilters.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons'
import { AllFilters } from './Types'
import { SortByFilter } from '@src/components/sections/Filters/Types'

const sortingOptions = [
  { label: 'Nýjasta', value: 'DATE_DESC' },
  { label: 'Verð: hæsta', value: 'PRICE_DESC' },
  { label: 'Verð: lægsta', value: 'PRICE_ASC' }
]

export default ({
  className,
  totalProducts,
  priceFieldProps,
  sortFieldProps,
  subcategoryFieldProps,
  brandFieldProps,
  isSnyrtivara // todo::add black theme
}) => {
  const [open, setIsOpen] = useState()
  const [openAllFilters, setOpenAllFilters] = useState(false)

  const toggleOpen = (state, func) => {
    func(state)
    // This is a safari mobile overflow workaround
    if (state) {
      document.getElementById('gatsby-focus-wrapper').style.overflow = 'hidden'
    } else if (!state) {
      document.getElementById('gatsby-focus-wrapper').style.overflow = 'scroll'
    }
  }

  const filterOptions = [
    {
      label: 'Vörumerki',
      typename: 'Brand',
      filterProps: brandFieldProps
    },
    {
      label: 'Vörutegund',
      typename: 'Subcategory',
      filterProps: subcategoryFieldProps
    },
    {
      label: 'Verð',
      typename: 'price',
      filterProps: priceFieldProps
    }
  ]
  return (
    <Container className='px-0'>
      <Row className={cn(className, styles.btnWrapper)}>
        <button
          className='col-6 d-flex justify-content-center align-items-center no-style eyebrow'
          onClick={() => toggleOpen(true, setOpenAllFilters)}
        >
          Sía eftir
          <FontAwesomeIcon className='ml-1' icon={faAngleDown} />
        </button>

        <button
          onClick={() => toggleOpen(true, setIsOpen)}
          className={cn(
            'no-style eyebrow col-6 d-flex justify-content-center align-items-center',
            styles.button
          )}
        >
          Raða eftir
          <FontAwesomeIcon
            className='ml-1'
            icon={open ? faAngleUp : faAngleDown}
          />
        </button>
        {open && (
          <div
            className={cn(styles.dropdownWrapper, {
              [styles.open]: open
            })}
          >
            <SortByFilter
              {...sortFieldProps}
              options={sortingOptions}
              className='filter__sortByMobile'
            />
            <button
              className={styles.filterClickZone}
              onClick={() => toggleOpen(false, setIsOpen)}
            />
          </div>
        )}
      </Row>
      {openAllFilters && (
        <AllFilters
          title='Vörusíun'
          onClick={() => toggleOpen(false, setOpenAllFilters)}
          filterOptions={filterOptions}
          totalProducts={totalProducts}
          backBtnIcon='close'
        />
      )}
    </Container>
  )
}
