import React from 'react'
import style from './SearchField.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-solid-svg-icons'
import useQueryParams from '@root/src/hooks/useQueryParams'
import cn from 'classnames'

export default ({
  resultPage,
  placeholder = 'Vöruleit',
  onSubmit = () => {},
  onChange = () => {}
}) => {
  const { q: qraw = '' } = useQueryParams()
  const q = qraw.trim()
  return (
    <div className={cn(style.input, { [style.higher]: resultPage })}>
      <input
        className='form-control'
        type='text'
        defaultValue={q}
        placeholder={placeholder}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            onSubmit(e)
          } else {
            onChange(e)
          }
        }}
      />
      <FontAwesomeIcon icon={faSearch} />
    </div>
  )
}
