import React from 'react'
import { RawHtml } from '@kogk/common'
import SliceWrapper from '@cmp/site/SliceWrapper'

const Embed = ({ html }) => (
  <SliceWrapper className='pb-3 pt-3'>
    <RawHtml el='div'>{html}</RawHtml>
  </SliceWrapper>
)

export default Embed