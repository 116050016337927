import React from 'react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { Row, Container } from '@kogk/common'
import InputWithDropdown from '@cmp/site/InputWithDropdown'
import { ListFilter, PriceSliderFilter, SortByFilter } from './Types'
import { SearchField } from '@cmp/sections'
import cn from 'classnames'

const sortingOptions = [
  { label: 'Nýjasta', value: 'DATE_DESC' },
  { label: 'Verð: hæsta', value: 'PRICE_DESC' },
  { label: 'Verð: lægsta', value: 'PRICE_ASC' }
]

const Filters = ({
  searchFieldProps = {},
  priceFieldProps = {},
  sortFieldProps = {},
  subcategoryFieldProps = {},
  brandFieldProps = {},
  isSnyrtivara
}) => {
  return (
    <>
      <Container id='topofgrid' className={cn({ 'black-theme': isSnyrtivara })}>
        <Row className='d-none d-md-flex'>
          <div className='col-6 col-md pt-3'>
            <SearchField {...searchFieldProps} resultPage />
          </div>
          <div className='col-6 col-md pt-3'>
            <div className='position-relative'>
              <InputWithDropdown
                label='Vörutegund'
                active={subcategoryFieldProps.selected.length > 0}
                icon={subcategoryFieldProps.selected.length > 0 && faCheck}
              >
                <ListFilter {...subcategoryFieldProps} />
              </InputWithDropdown>
            </div>
          </div>
          {brandFieldProps.allItems && (
            <div className='col-6 col-md pt-3'>
              <div className='position-relative'>
                <InputWithDropdown
                  label='Vörumerki'
                  active={brandFieldProps.selected.length > 0}
                  icon={brandFieldProps.selected.length > 0 && faCheck}
                >
                  <ListFilter {...brandFieldProps} />
                </InputWithDropdown>
              </div>
            </div>
          )}

          <div className='col-6 col-md pt-3'>
            <InputWithDropdown
              active={
                priceFieldProps.minPrice !== priceFieldProps.currentMin ||
                priceFieldProps.maxPrice !== priceFieldProps.currentMax
              }
              label='Verðbil'
            >
              <PriceSliderFilter {...priceFieldProps} />
            </InputWithDropdown>
          </div>
          <div className='col-6 col-md pt-3'>
            <InputWithDropdown label='Raða eftir'>
              <SortByFilter options={sortingOptions} {...sortFieldProps} />
            </InputWithDropdown>
          </div>
        </Row>
        <div className='d-block d-md-none'>
          <Row>
            <div className='col-12 pt-2 mt-1 mt-md-0 pt-md-3'>
              <SearchField {...searchFieldProps} />
            </div>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Filters
