import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import { StrippedSlider } from '@cmp/sections'
import styles from './CustomSlider.module.scss'
import cn from 'classnames'
import { Link } from '@kogk/gatsby-theme-base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { useSlideScroll } from '@src/hooks/useSlideScroll'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons'


const Slider = ({ products, scrollContainer, heading, tag }) => {
  const { t } = useLanguage()
  return (
    <div className={cn('pt-3 mb-1', styles.sliderWrapper)}>
      <Container>
        <Row>
          <Col className='d-flex justify-content-between pb-2 pb-lg-3' col={12}>
            <h3 className='d-none d-md-inline mb-0 slider-title blue'>
              {heading}
            </h3>

            {tag && (
              <Link
                to={tag}
                className={cn('link link--small blue', styles.categoryLink)}
              >
                <span className='d-none d-md-inline'>{t`see all`}</span>
                <span className='d-inline d-md-none'>{heading}</span>
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            )}
          </Col>
        </Row>
      </Container>
      <StrippedSlider
        widthBorder={true}
        products={products}
        scrollContainer={scrollContainer}
      />
    </div>
  )
}


export default ({ heading, tag, products }) => {
  const productsA = products
  const arrowStyles = cn(
    styles.arrowButton,
    'no-style d-none d-lg-flex align-items-center arrowBtn'
  )
  const numberOfProducts = productsA?.length
  const active = index => numberOfProducts > index

  const { scrollContainer, showLeftArrow, scrollItems, showRightArrow } =
    useSlideScroll(numberOfProducts, 6)

  if (active(6)) {
    return (
      <div className={cn('d-block d-lg-flex', styles.containerWrapper)}>
        <button
          disabled={!showLeftArrow}
          onClick={() => scrollItems(-1)}
          className={cn('justify-content-start', arrowStyles)}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      
      <Slider products={productsA} scrollContainer={scrollContainer} heading={heading} tag={tag} />
        <button
          disabled={!showRightArrow}
          onClick={() => scrollItems(1)}
          className={cn(arrowStyles, 'justify-content-end')}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    )
  } else if (active(0)) {
    return (
      <Slider products={productsA} scrollContainer={scrollContainer} heading={heading} tag={tag} />
    )
  }
  return <></>
}
