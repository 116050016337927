import { useState, useRef, useCallback, useEffect } from 'react'

export const useSlideScroll = (itemCount, displayItemCount) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  // Check if cards in slider are more than slots in slider
  const [showRightArrow, setShowRightArrow] = useState(
    itemCount > displayItemCount
  )
  const scrollContainer = useRef(null)
  let scrollElement

  const setArrows = useCallback(
    init => {
      if (!scrollElement) {
        return
      }
      if (init) {
        scrollElement.scrollLeft = 0
      }
      const atStart = scrollElement.scrollLeft === 0
      if (atStart && showLeftArrow) {
        setShowLeftArrow(false)
      }
      if (!atStart && !showLeftArrow) {
        setShowLeftArrow(true)
      }

      const atEnd =
        scrollElement.scrollLeft + 1 >=
        scrollElement.scrollWidth - scrollElement.clientWidth
      if (atEnd && showRightArrow) {
        setShowRightArrow(false)
      }
      if (!atEnd && !showRightArrow) {
        setShowRightArrow(true)
      }
    },
    [scrollElement, showLeftArrow, showRightArrow]
  )

  useEffect(() => {
    scrollElement = scrollContainer.current

    const onScroll = e => {
      setArrows()
    }
    scrollElement?.addEventListener('scroll', onScroll)
    return () => {
      scrollElement?.removeEventListener('scroll', onScroll)
    }
  }, [scrollElement, setArrows])

  const scrollItems = isNegative => {
    if (scrollContainer.current) {
      // Use width of second child, first child might be scrolled to the side
      const itemWidth = scrollContainer.current.children[1].clientWidth
      // Get how many children can be shown at current size
      const activeChildrenAmount = Math.round(
        scrollContainer.current.clientWidth / itemWidth
      )
      // Scroll
      scrollContainer.current.scrollLeft +=
        activeChildrenAmount * itemWidth * isNegative
    }
  }

  return {
    /**
     * Boolean ture if container has scrolled
     */
    showLeftArrow,
    /**
     * Boolean ture if container has not reached end
     */
    showRightArrow,
    /**
     * Force arrow calculations - usefull if context changes
     */
    setArrows,
    /**
     * Scroll container ref
     */
    scrollContainer,
    /**
     * Scroll by x items
     */
    scrollItems
  }
}
