import React from 'react'
import { ButtonLink } from '@cmp/site'
import cn from 'classnames'
import styles from './CartBtnMobile.module.scss'

export default ({
  cartItems,
  onClick,
  isSnyrtivara
}) => {
  const theme = isSnyrtivara ? styles.snyrtivara : ''
  return (
    <div className={cn(theme, 'pl-2 ml-1')}>
      <ButtonLink
        className={styles.cartBtn}
        label='Karfan þín'
        icon='cart'
        large
        rounded
        to='/karfa'
      />
      <span className={styles.tab}>{cartItems}</span>
    </div>
  )
}
