import React from 'react'

export default ({ title = 'Eitthvað fór úrskeiðis', error }) => {
  // TODO make pretty
  return (
    <div>
      <p>{title}</p>
      <pre>{'' + error}</pre>
    </div>
  )
}
