import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import { ProductRow } from '@cmp/site'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faUser } from '@fortawesome/pro-solid-svg-icons'
import styles from './CartQuickView.module.scss'
import { formatPriceString } from '@root/src/utils/index'
import ButtonLink from '@cmp/site/ButtonLink'
import { useCartVisibility } from '@root/src/context/CartVisibility'

export default ({ isSnyrtivara, cart, onClick, isEmpty }) => {
  const cartVisibility = useCartVisibility()

  return (
    <Container className={styles.container}>
      {isEmpty ? (
        <h4 className='blue mb-0 text-center w-100'>Karfan þín er tóm</h4>
      ) : (
        <>
          <Row className='d-xl-none'>
            <Col
              col={12}
              className={cn(
                'd-flex d-xl-none justify-content-between align-items-start',
                styles.mobileHeader
              )}
            >
              <button onClick={onClick} className='no-style pl-0'>
                <FontAwesomeIcon icon={faArrowLeft} size='xs' />
              </button>

              <h5 className='mb-0'>Karfan þín</h5>

              <FontAwesomeIcon icon={faUser} size='xs' />
            </Col>
          </Row>
          <Row>
            <Col col={12} className={styles.innerContainer}>
              {cart.products.map(item => {
                const productVariant =
                  item.product.title !== item.productGroup.title
                    ? item.product.title
                    : null
                return (
                  <ProductRow
                    key={item.id}
                    image={
                      item.productGroup.images[0] &&
                      (item.productGroup.images[0].largeUrl ??
                        item.productGroup.images[0].thumbnailUrl)
                    }
                    description={item.productGroup.title}
                    price={item.totalPrice}
                    quantity={item.quantity}
                    maxQuantity={item.product.maxQuantity}
                    variants={[productVariant]}
                    quickView
                    productType='product'
                    id={item.id}
                    productLink={`/${item.productGroup.category.slug}/${item.productGroup.id}`}
                    discount={item.discountPercent}
                    fullPrice={item.totalDiscount + item.totalPrice}
                  />
                )
              })}
              {cart.prescriptions.map(item => (
                <ProductRow
                  key={item.id}
                  image={''}
                  description={item.prescription.description}
                  price={item.totalPrice}
                  quantity={item.quantity}
                  maxQuantity={item.prescription.maxQuantity}
                  variants={[]}
                  quickView
                  productType='prescription'
                  id={item.id}
                  discount={item.discountPercent}
                  fullPrice={item.totalDiscount + item.totalPrice}
                />
              ))}
            </Col>
          </Row>
          <Row className={styles.bottomContainer}>
            <Col col={12} className='d-flex justify-content-between pt-2'>
              <p className='parag--medium opacity--08'>Samtals</p>
              <p className={styles.totalSum}>
                {formatPriceString(cart.totalPrice, true)}
              </p>
            </Col>
            <Col
              col={12}
              className={cn(
                { snyrtivara: isSnyrtivara },
                'd-flex justify-content-between w-100'
              )}
            >
              <ButtonLink
                to='/karfa'
                type='ghost'
                className='w-50 mr-1'
                label='Sjá körfu'
                onClick={onClick}
              />
              <span className='w-50 ml-1' onBlur={cartVisibility.close}>
                <ButtonLink
                  to='/checkout'
                  type='primary'
                  label='Klára kaup'
                  onClick={onClick}
                />
              </span>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}
