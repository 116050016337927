import React from 'react'
import styles from './ValidationMessage.module.scss'

export default ({
  message = () => console.log('Message string missing')
}) => {
  if (message === false) return null

  return (
    <span className={styles.validationMsg}>
      <p className='parag--small-bold mb-0 d-inline'>{message}</p>
      <span className={styles.icon}>!</span>
    </span>
  )
}
