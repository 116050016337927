import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './ProgressBar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/pro-regular-svg-icons'

export default ({
  progress,
  icon = '',
  size = 30,
  strokeWidth = 2,
  center = size / 2,
  radius = size / 2 - strokeWidth / 2,
  circumference = 2 * Math.PI * radius,
  rotate
}) => {
  const [offset, setOffset] = useState(0)
  const icons = {
    clipboard: faClipboard
  }

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference
    setOffset(progressOffset)
  }, [setOffset, progress, circumference, offset])

  return (
    <span className={styles.wrapper}>
      <svg
        className={cn({ [styles.rotate]: rotate }, styles.circleChart)}
        width={size}
        height={size}
      >
        <circle
          className={styles.circleBg}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          className={styles.circle}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
      {icon && <FontAwesomeIcon className={styles.icon} icon={icons[icon]} />}
    </span>
  )
}
