import React from 'react'
import { Row, Col, Container, handleImgixUrl } from '@kogk/common'
import Img from 'gatsby-image'

export default ({ image, mobile }) => {
  const fluid = image
    ? handleImgixUrl(image.url).gatsbyImageFluid()
    : null
  const fluidMobile = mobile
    ? handleImgixUrl(mobile.url).gatsbyImageFluid()
    : null
  return (
    <Container fluid className='px-0'>
      <Row>
        <Col col={12}>
          {fluid && (
            <Img alt={image.alt} fluid={fluid} className='d-none d-md-block' />
          )}
          <Img fluid={fluidMobile} className='d-block d-md-none' />
        </Col>
      </Row>
    </Container>
  )
}
