import React from 'react'
import Button from '@cmp/site/Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import cn from 'classnames'

const FilterList = ({ allItems, selected, onChange, onClick, title, bottomSpace }) => {
  const toggleFilter = item => {
    onChange(
      selected.includes(item.id)
        ? selected.filter(id => id !== item.id)
        : selected.concat([item.id])
    )
  }

  const clear = () => onChange([])

  return (
    <div className='filter'>
      <div className='filter__header d-flex w-100 justify-content-between align-items-center'>
        <p className='d-none d-md-block filter__counter w-100 mb-0'>{`${selected.length} valið`}</p>
        <Button
          onClick={onClick}
          icon='arrowLeft'
          type='blanco'
          className='d-block d-md-none pl-0'
        />
        <h5 className='mb-0 text-center w-100'>{title}</h5>
        {selected.length > 0 && (
          <Button
            onClick={clear}
            label={'Hreinsa'}
            className='filter__clear-btn border bold'
            type='mini-ghost'
            icon={selected.length > 0 ? null : 'check'}
          />
        )}
      </div>
      {allItems.map((item, i) => (
        <button
          key={i}
          onClick={() => toggleFilter(item)}
          className={cn(
            { 'border-top': i !== 0 },
            { 'mb-4': bottomSpace && i === allItems.length - 1 },
            'filter__item p-0 no-style d-flex justify-content-between align-items-center w-100'
          )}
        >
          <p className='mb-0 text-left' key={item.id}>
            {item.title}
          </p>
          {selected.includes(item.id) && <FontAwesomeIcon icon={faCheck} />}
        </button>
      ))}
    </div>
  )
}

export default FilterList
