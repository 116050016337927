import React from 'react'
import { Button, ButtonLink } from '@cmp/site'
import cn from 'classnames'
import { useFavorites } from '@src/context/Favorites'
import styles from './FavoriteBtn.module.scss'

export default ({
  id,
  className
}) => {
  const favorites = useFavorites()
  const isFavorite = favorites.favoriteItems.includes(id)
  const icon = isFavorite ? 'heartFull' : 'heart'
  const favBtn = cn(
    className,
    'favorite-btn', {
    'favorite-btn--full': isFavorite
  })

  return (
    <Button
      icon={icon}
      type='transparent-mini'
      className={favBtn}
      onClick={() => favorites.toggleAsFavorite(id)}
    />
  )
}