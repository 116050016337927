import React, { useMemo, Fragment } from 'react'
import cn from 'classnames'
import uniq from 'lodash/uniq'
import styles from './Pagination.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-regular-svg-icons'

// gatsby doesn't seem to update scroll position when Link only changes query
// parameters. So we scroll to top manually, but we have to delay the execution
// until navigation has completed, hence the setTimeout. If we don't use
// setTimeout, the browser will scroll to top before navigating and gatsby
// will save the scroll position at the top of page
const scrollTop = () => {
  setTimeout(
    () =>
      document
        .getElementById('topofgrid')
        .scrollIntoView({ behavior: 'smooth' }),
    0
  )
}

const range = (min, max) =>
  new Array(max - min + 1).fill(0).map((_, i) => min + i)

export default ({ totalPages, currentPage, renderLink, isSnyrtivara }) => {
  const ranges = useMemo(() => {
    return uniq(
      range(1, 2)
        .concat(range(currentPage - 1, currentPage + 1))
        .concat(range(totalPages - 1, totalPages))
        .filter(p => 1 <= p && p <= totalPages)
        .sort((a, b) => a - b)
    )
  }, [currentPage, totalPages])

  const renderEdgeLink = (page, children) => {
    if (1 <= page && page <= totalPages) {
      return renderLink({
        page,
        onClick: scrollTop,
        className: cn(styles.link, styles.edge),
        children
      })
    }

    return (
      <span className={cn(styles.link, styles.edge, styles.edgeDisabled)}>
        {children}
      </span>
    )
  }

  return (
    <div
      className={cn(
        { [styles.blackTheme]: isSnyrtivara },
        'd-flex justify-content-center align-items-center'
      )}
    >
      {renderEdgeLink(
        currentPage - 1,
        <FontAwesomeIcon icon={faChevronLeft} />
      )}
      {ranges.map((page, i) => (
        <Fragment key={page}>
          {i > 0 && ranges[i - 1] !== page - 1 && (
            <span className={styles.gap}>&hellip;</span>
          )}
          {renderLink({
            page,
            className: cn(styles.link, {
              [styles.linkActive]: page === currentPage
            }),
            onClick: scrollTop,
            children: <>{page}</>
          })}
        </Fragment>
      ))}
      {renderEdgeLink(
        currentPage + 1,
        <FontAwesomeIcon icon={faChevronRight} />
      )}
    </div>
  )
}
