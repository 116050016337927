import React, { useState } from 'react'
import RadioButton from '@cmp/site/RadioButton'
import cn from 'classnames'

const SortByFilter = ({ options = [], className, selected, onChange }) => {
  return (
    <div className={cn(className, 'filter')}>
      {options.map((s, i) => (
        <RadioButton
          className='w-100'
          key={i}
          label={s.label}
          onClick={() => onChange(s.value)}
          checked={selected === s.value}
        />
      ))}
    </div>
  )
}

export default SortByFilter
