import { useState, useMemo, useEffect } from 'react'
import qs from 'qs'
import { useQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import useQueryParams from '@src/hooks/useQueryParams'
import { mergeQueryParams } from '@src/utils'
import { PRODUCT_SEARCH } from '@src/data/backend/queries'

const defaultSort = 'SCORE'
const validSorts = ['SCORE', 'PRICE_ASC', 'PRICE_DESC', 'DATE_ASC', 'DATE_DESC']

const parseIntDefault = (s, _default) => {
  const n = parseInt(s, 10)
  return isNaN(n) ? _default : n
}

const toArray = v => {
  if (v == null) return []
  return Array.isArray(v) ? v : [v]
}

const useDebounced = (currentValue, delay = 200) => {
  const [value, setValue] = useState(currentValue)

  const setValueDebounced = useMemo(() => {
    return debounce(setValue, delay)
  }, [setValue])

  useEffect(() => {
    setValueDebounced(currentValue)
  }, [setValueDebounced, currentValue])

  return value
}

const useLoadedQuery = query => {
  const [loadedQuery, setLoadedQuery] = useState(query)

  useEffect(() => {
    if (query !== loadedQuery && !query.loading) {
      setLoadedQuery(query)
    }
  }, [query])

  return loadedQuery
}

const onFilterChange = (k, v) => {
  const params = { [k]: v || undefined }
  // reset page number when user changes filters
  if (k !== 'page') {
    params.page = undefined
  }
  // reset order when search string changes
  if (k === 'q') {
    params.order = undefined
  }
  mergeQueryParams(params)
}

export default ({ categoryId, brandId } = {}) => {
  // current query parameters
  const currentParams = useQueryParams()

  // query parameters that are updated with a delay so we don't flood the
  // backend with requests when search term or filters change
  const params = useDebounced(currentParams)

  const order = validSorts.includes(params.order) ? params.order : defaultSort

  const productFilter = Number(toArray(params.s)[0])

  // make search using debounced params
  const latestSearch = useQuery(PRODUCT_SEARCH, {
    ssr: false,
    variables: {
      input: {
        company: 'LYF_OG_HEILSA',
        page: parseIntDefault(currentParams.page),
        perPage: 24,
        query: params.q,
        minPrice: parseIntDefault(params.min),
        maxPrice: parseIntDefault(params.max),
        categoryId,
        subcategoryId: !productFilter ? (params.s ? toArray(params.s) : null) : null,
        brandId: brandId ?? (params.b ? toArray(params.b) : null),
        sort: order,
        tags: toArray(params.tag),
        products: productFilter ? params.s : null
      }
    }
  })

  // while new search is loading we want to show results from the previous search
  const search = useLoadedQuery(latestSearch)

  // extract data from response and return
  const data = search.data || {}
  const { nodes, pageInfo, aggregations: aggs } = data.productGroups || {}
  const minPriceAvailable = aggs ? aggs.minPrice : 0
  const maxPriceAvailable = aggs ? aggs.maxPrice : 0
  const query = (search.variables.input.query || '').trim()

  return {
    query,
    currentPage: parseIntDefault(currentParams.page, 1),
    totalPages: pageInfo && pageInfo.totalPages,
    products: nodes || [],
    totalProducts: pageInfo && pageInfo.totalResults,
    loading: latestSearch.loading,
    order: currentParams.order,
    minPriceAvailable,
    maxPriceAvailable,
    minPriceSelected: parseIntDefault(currentParams.min, minPriceAvailable),
    maxPriceSelected: parseIntDefault(currentParams.max, maxPriceAvailable),
    subcategories: (aggs ? aggs.subcategories : []).map(a => a.subcategory),
    selectedSubcategories: toArray(currentParams.s),
    brands: (aggs ? aggs.brands : []).map(a => a.brand),
    selectedBrands: toArray(currentParams.b),
    onFilterChange,
    makeHref: otherParams => {
      const s = qs.stringify({ ...currentParams, ...otherParams })
      return s && '?' + s
    }
  }
}
