import React from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import { Button } from '@cmp/site'
import cn from 'classnames'

export default ({ text, type, onClick  }) => {
  return (
    <Container
      fluid
      className={cn(
        'announcement-banner',
        `announcement-banner--${type}`
      )}
    >
      <Row className='announcement-banner__content d-flex align-items-start align-items-md-center'>
        <Col col={10}>
          <div className='announcement-banner__text' dangerouslySetInnerHTML={{ __html: text }} />
        </Col>
        <Col col={1} className='d-flex justify-content-end'>
          <Button
            onClick={onClick}
            type='transparent-mini'
            icon='close'
          />
        </Col>
      </Row>
    </Container>
  )
}