import React from 'react'
import cn from 'classnames'

const RadioButton = ({
  label = '',
  checked = false,
  disabled = false,
  onClick,
  className = '',
  children
}) => {
  return (
    <button
      onClick={!disabled && onClick}
      className={cn(
        'no-style filter__item d-flex align-items-center radio-button',
        className,
        { 'opacity--04': disabled }
      )}
    >
      <div
        className={cn('radio-button__input mr-2', {
          'radio-button__input--checked': checked
        })}
      >
        <span />
      </div>
      {label && <p className='mb-0'>{label}</p>}
      {children}
    </button>
  )
}

export default RadioButton
