import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import cn from 'classnames'

import styles from './HeroShorter.module.scss'

export default () => {
  return (
    <Container>
      <Row>
        <Col>
          <h2>HeroShorter component</h2>
        </Col>
      </Row>
    </Container>
  )
}