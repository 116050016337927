import React, { useState, useCallback, useEffect } from 'react'
import cn from 'classnames'
import styles from './NativeSelect.module.scss'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default React.forwardRef(({ options, hasError, ...props }, ref) => {
  const [hasFocus, setHasFocus] = useState(false)
  const [selectedValue, setSelectedValue] = useState(
    props.value ?? props.defaultValue ?? options[0]?.value
  )

  useEffect(() => setSelectedValue(props.value), [props.value])

  return (
    <div
      className={cn(styles.container, {
        [styles.containerError]: hasError,
        [styles.containerFocus]: hasFocus
      })}
    >
      {options.find(o => o.value === selectedValue)?.label}
      <FontAwesomeIcon icon={faChevronDown} className={styles.arrow} />
      <select
        {...props}
        onChange={e => {
          setSelectedValue(e.target.value)
          props.onChange?.(e)
        }}
        onFocus={e => {
          setHasFocus(true)
          props.onFocus?.(e)
        }}
        onBlur={e => {
          setHasFocus(false)
          props.onBlur?.(e)
        }}
        ref={ref}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  )
})
