import React from 'react'
import styles from './MenuDesktop.module.scss'
import PropTypes from 'prop-types'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

const MenuDesktop = ({ items, title, subMenu }) => {
  const moreLinkProps = {
    title: 'Fleira',
    subMenu: subMenu,
    url: ''

  }

  return (
    <section className={styles.navigation} aria-label={title} title={title}>
      <ul className='d-flex'>
        {items &&
          items.mainMenu.map(item =>
            <MenuItem key={item.title} item={item} className='white' />
          )}
          {subMenu && (
            <MenuItem key={subMenu} item={moreLinkProps} className='white'>
              <Submenu items={subMenu} />
              <FontAwesomeIcon icon={faAngleDown} className='white' />
            </MenuItem>
          )}
      </ul>
    </section>
  )
}

export default MenuDesktop

const itemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    subMenu: PropTypes.array
  })
).isRequired

MenuDesktop.propTypes = {
  items: itemsPropType,
  title: PropTypes.string
}

const MenuItem = ({ item, children, className }) => {
  const linkClasses = cn(
    'd-block mt-1 mb-1 px-1 px-xxl-2  link link--nav-main',
    className
  )
  return (
    <li key={item.url}>
      {children ? (
        <button
          aria-label={item.title}
          activeClassName={styles.menuItemActive}
          className={cn('no-style', linkClasses)}
        >
          {item.title}
        </button>
      ) : (
        <Link
          to={item.url}
          activeClassName={styles.menuItemActive}
          className={linkClasses}
        >
          {item.title}
        </Link>
      )}
      {children}
    </li>
  )
}

const Submenu = ({ items }) => (
  <div className={styles.submenu}>
    <ul>
      {items.map(item => (
        <MenuItem key={item.title} item={item} />
      ))}
    </ul>
  </div>
)
