import React, { useContext } from 'react'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import cn from 'classnames'
import styles from './ViewType.module.scss'
import { ViewTemplateContext } from '@root/src/context/ViewTemplateContext'

export default () => {
  const { content } = useContext(ViewTemplateContext)

  return (
    <Container>
      <Row>
        <Col>
          <h1 className='hdln--5 blue parag--medium  mb-0'>{content.orderNo}</h1>
          <p className='parag--medium opacity--06 mb-0'>{content.date}</p>
        </Col>
      </Row>
      <Row>
        {content.products.map((item, key) => {
          return (
            <Col
              key={key}
              col={12}
              className={cn(
                { [styles.border]: key + 1 !== content.products.length },
                'd-flex justify-content-between parag--x-small py-2'
              )}
            >
              <span className={styles.rowCol}>{item.name}</span>
              <span className='text-center'>{item.quantity}x</span>
              <span className='opacity--08'>{item.price} kr.</span>
            </Col>
          )
        })}
      </Row>
      <Row>
        <Col col={12} className='d-flex justify-content-between pt-1'>
          <p className='parag--medium opacity--08 mb-0'>Samtals</p>
          <p className='price-tag mb-0'>{content.total} kr.</p>
        </Col>
      </Row>
    </Container>
  )
}
