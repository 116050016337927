import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons'
import cn from 'classnames'

import styles from './FAQ.module.scss'

export default ({ heading, questions, text }) => {
  return (
    <Container className='py-3 mb-1'>
      <Row>
        <FancyShow>
          <Col>
            {heading && (<h2 className='hdln--2 blue pb-md-2'>{heading}</h2>)}
            {text && (<p className='parag--small-fixed-size'>{text}</p>)}
          </Col>
        <FancyShow>
        </FancyShow>
          <Col col={12} className={styles.faqWrapper}>
            {questions && questions.map((item, key) => {
              return <FaqItem key={key} question={item.question} answer={item.answer} />
            })}
          </Col>
        </FancyShow>
      </Row>
    </Container>
  )
}

const FaqItem = ({ question, answer }) => {
  const [collapsed, setCollapsed] = useState(true)
  useEffect(() => {
    setCollapsed(true)
  }, [])

  return (
    <div className={styles.answerWrapper}>
      <button
        onClick={() => setCollapsed(!collapsed)}
        className={cn(
          styles.button,
          'pl-0 no-style d-flex align-items-center justify-content-between w-100'
        )}
      >
        <h3 className='parag--medium-bold mb-0' >{question.text}</h3>
        <span>
          {collapsed ? (
            <FontAwesomeIcon icon={faPlus} />
          ) : (
            <FontAwesomeIcon icon={faMinus} />
          )}
        </span>
      </button>

      <div
        className={cn(
          'parag--small-fixed-size px-2',
          styles.collapseSection,
          { [styles.collapsed]: collapsed }
          )}
        dangerouslySetInnerHTML={{ __html: answer.html }}
      />
    </div>
  )
}