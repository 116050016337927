import React, { useState, useEffect } from 'react'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import { useGlobalData } from '@src/data/global-data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRaindrops } from '@fortawesome/pro-solid-svg-icons'
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons'
import styles from './AllarVerslanir.module.scss'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { ButtonLink } from '@cmp/site'

export default () => {
  const { verslanir } = useGlobalData()

  return (
    <Container>
      {verslanir &&
        verslanir
          .filter(i => i.fyrirtaeki === 'Lyf og Heilsa')
          .map(
            (
              {
                address,
                lyfsoluleyfishafi,
                thjonusta,
                storeName,
                simanumer,
                opnunarTimar,
                kort,
                email,
                hnit
              },
              key
            ) => {
              return (
                <Verslun
                  key={key}
                  address={address}
                  lyfsoluleyfishafi={lyfsoluleyfishafi}
                  thjonusta={thjonusta}
                  storeName={storeName}
                  simanumer={simanumer}
                  opnunarTimar={opnunarTimar}
                  kort={kort}
                  email={email}
                  hnit={hnit}
                />
              )
            }
          )}
    </Container>
  )
}

export const Verslun = ({
  address,
  lyfsoluleyfishafi,
  thjonusta,
  storeName,
  simanumer,
  opnunarTimar,
  kort,
  email
}) => {
  const [collapsed, setCollapsed] = useState(true)
  const emailLink = `mailto:${email}`
  const { t } = useLanguage()

  useEffect(() => {
    setCollapsed(true)
  }, [])

  const consealableItemsClasses = cn(
    styles.collapseSection,
    { [styles.collapsed]: collapsed },
    'd-flex flex-column flex-lg-row w-100 justify-content-between'
  )

  return (
    <Row className={cn(styles.row, styles.border)}>
      <Col col={12} className='d-flex justify-content-between pb-1'>
        <div>
          <h4 className='mb-1'>{storeName}</h4>
          <p className={cn(styles.infoText, 'opacity--07')}>{address}</p>
        </div>

        <button
          className={cn('no-style d-flex d-lg-none', styles.closeButton)}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <FontAwesomeIcon icon={faPlus} />
          ) : (
            <FontAwesomeIcon icon={faMinus} />
          )}
        </button>
      </Col>

      <div className={consealableItemsClasses}>
        <div className='col-7 col-lg-2 d-flex flex-column pl-xl-0'>
          {thjonusta[0] !== '' &&
            thjonusta.map((i, key) => {
              return (
                <div key={key} className={styles.serviceSection}>
                  <FontAwesomeIcon icon={faRaindrops} />
                  <span className={cn(styles.infoText, 'pl-2')}>{i}</span>
                </div>
              )
            })}
        </div>

        <Col className='col-7 col-lg-2 mb-2 mb-md-0'>
          {opnunarTimar &&
            opnunarTimar.map(({ dagur, timi }, key) => {
              return (
                <div key={key} className='d-flex justify-content-between'>
                  <p className={styles.labelText}>{dagur}</p>
                  <p className={styles.infoText}>{timi}</p>
                </div>
              )
            })}
        </Col>

        <Col className='order-4 order-lg-3 col-7 col-lg-2'>
          {simanumer &&
            simanumer.map(({ numer, nafn }, key) => {
              return (
                <div key={key} className='d-flex justify-content-between'>
                  <p className={styles.labelText}>{nafn}</p>
                  <p className={styles.infoText}>{numer}</p>
                </div>
              )
            })}
        </Col>

        <Col className='order-3 order-lg-4 col-7 col-sm-6 col-lg-3'>
          <div className={styles.labelText}>{t(`general|leyfishafi`)}</div>
          <p className={styles.infoText}>{lyfsoluleyfishafi}</p>
          <a
            href={emailLink}
            className={cn(
              styles.infoText,
              'text-nowrap d-block d-md-inline pb-2'
            )}
          >
            {email}
          </a>
        </Col>

        <Col className='order-5 col-12 col-lg-1 d-flex justify-content-lg-end pt-2 pb-2 pr-0'>
          <ButtonLink
            type='map'
            href={kort}
            target='blank'
            className='w-100'
            label={t(`general|map`)}
          />
        </Col>
      </div>
    </Row>
  )
}
