import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faShoppingCart,
  faCheck,
  faAngleDown,
  faCreditCard,
  faHome,
  faArrowLeft,
  faTimes,
  faHeart as heartFull,
  faRedo
} from '@fortawesome/pro-solid-svg-icons'
import {
  faSignOut,
  faChevronDown,
  faSpinner,
  faSearch,
  faTimes as faTimesRegular
} from '@fortawesome/pro-regular-svg-icons'
import {
  faPlus,
  faMinus,
  faHeart as heart,
  faInfoCircle
} from '@fortawesome/pro-light-svg-icons'

export default React.forwardRef(
  (
    {
      label = '',
      onClick = () => console.log('missing onClick function'),
      onFocus,
      className = '',
      icon,
      children,
      disabled,
      spin = icon === 'loading',
      type = 'primary'
    },
    ref
  ) => {
    const icons = {
      user: faUser,
      cart: faShoppingCart,
      check: faCheck,
      chevronDown: faChevronDown,
      signOut: faSignOut,
      angleDown: faAngleDown,
      card: faCreditCard,
      home: faHome,
      arrowLeft: faArrowLeft,
      close: faTimes,
      info: faInfoCircle,
      minus: faMinus,
      plus: faPlus,
      redo: faRedo,
      loading: faSpinner,
      heart: heart,
      heartFull: heartFull,
      closeRegular: faTimesRegular,
      search: faSearch
    }

    return (
      <button
        disabled={disabled}
        ref={ref}
        className={cn(
          className,
          'removeGenericButtonStyles',
          'the-button',
          `the-button__${type}`
        )}
        type={type}
        onClick={onClick}
        onFocus={onFocus}
      >
        {icons[icon] && (
          <FontAwesomeIcon
            icon={icons[icon]}
            spin={spin}
            className={cn(label && 'mr-1')}
          />
        )}
        {label && <span>{label}</span>}
        {children}
      </button>
    )
  }
)
