import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faShoppingCart,
  faArrowLeft,
  faHeart as heartFull
} from '@fortawesome/pro-solid-svg-icons'
import {
  faHeart as heart
} from '@fortawesome/pro-light-svg-icons'
import { Link } from '@kogk/gatsby-theme-base'

// primary, ghost, mini, mini-ghost

export default ({
  label = '',
  to = '/',
  className = '',
  type = 'primary',
  icon,
  children,
  disabled,
  spin = icon === 'loading',
  href = '',
  onClick,
  ...props
}) => {
  const icons = {
    user: faUser,
    cart: faShoppingCart,
    arrowLeft: faArrowLeft,
    heart: heart,
    heartFull: heartFull
  }

  return (
    <>
      {href !== '' ? (
        <a
          {...props}
          className={cn(
            { 'the-button--disabled': disabled },
            className,
            'the-button',
            `the-button__${type}`
          )}
          href={href}
          onClick={onClick}
        >
          {icons[icon] && (
            <FontAwesomeIcon
              icon={icons[icon]}
              className={cn(label && 'mr-1')}
            />
          )}
          <span>{label}</span>
          {children}
        </a>
      ) : (
        <Link
          {...props}
          className={cn(
            { 'the-button--disabled': disabled },
            className,
            'the-button',
            `the-button__${type}`
          )}
          to={to}
          onClick={onClick}
        >
          {icons[icon] && (
            <FontAwesomeIcon
              icon={icons[icon]}
              className={cn(label && 'mr-1')}
            />
          )}
          <span>{label}</span>
          {children}
        </Link>
      )}
    </>
  )
}
