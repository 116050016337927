import React, { useEffect, useContext } from 'react'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Row, Col, Container } from '@kogk/common'
import { MainLayout, Loading } from '@cmp/site'
import { CartVisibility } from '@root/src/context/CartVisibility'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'

const LoadingPage = () => {
  const { language } = useLanguage()
  const { modifyCartVisibility } = useContext(CartVisibility)
  const { modify } = useContext(MenuTypeContext)

  useEffect(() => {
    modifyCartVisibility(true)
    modify('primary')
  }, [])

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <Container className='mt-2 mt-md-0 pt-4 pt-md-6 mb-md-6'>
          <Row className='flex-column align-items-md-center justify-content-center text-md-center'>
            <Col className='col-6 col-md-5'>
              <Loading />
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default LoadingPage
