import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow,
  RawHtml
} from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import cn from 'classnames'

import styles from './FeaturedTextSlice.module.scss'

export default ({
  heading,
  leftCol,
  rightCol
}) => {
  return (
    <FancyShow>
      <Container className='pt-1 pt-md-5 pb-1 pb-md-4 mb-3'>
        <Row className='mb-md-1'>
          <Col col={12} className='mb-2 mb-md-4'>
            <FancyShow>
              <h2 className='blue mb-1'>{heading}</h2>
            </FancyShow>
          </Col>
          <Col col={{ md: 5 }}>
            <FancyShow>
              <RawHtml html={leftCol} className='article' />
            </FancyShow>
          </Col>
          <Col col={{ md: 5 }} offset={{ md: 2 }} >
            <FancyShow>
              <RawHtml html={rightCol} className='article' />
            </FancyShow>
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}
