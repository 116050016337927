import React, { useMemo } from 'react'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import { Card, Loading } from '@cmp/site/'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { useFavorites } from '@src/context/Favorites'
import { useQuery } from '@apollo/client'
import { PRODUCT_SEARCH } from '@src/data/backend/queries'
import styles from './GetFavorites.module.scss'

export default () => {
  const { t } = useLanguage()
  const favorites = useFavorites()
  const products = useMemo(() => favorites.favoriteItems, [favorites.isLoaded])

  const query = useQuery(PRODUCT_SEARCH, {
    ssr: false,
    skip: products.length === 0,
    variables: {
      input: {
        company: 'LYF_OG_HEILSA',
        products
      }
    }
  })

  const favoriteItems =
    (query.data ?? query.previousData)?.productGroups.nodes ?? []

  return (
    <Container>
      <Row>
        <Col col={12} className='pb-md-1 pt-3 pt-md-4'>
          <h1 className='mb-2 pb-1'>{t`favorites page|heading`}</h1>
          <p className='parag--x-small-fixed-size opacity--05 text-md-left text-center'>
            {favoriteItems.length + ' ' + t`favorites page|products`}
          </p>
        </Col>
      </Row>
      {!favorites.isLoaded || query.loading ? (
        <Loading />
      ) : (
        <Row className='flex-wrap px-2 px-md-0'>
          {favoriteItems.length > 0 ? (
            <>
              {favoriteItems.map(item => {
                return (
                  <div
                    key={item.id}
                    className={cn(
                      styles.cardWrapper,
                      'mb-3 mb-lg-4 col-6 col-sm-4 col-md-3 col-lg-2 px-1'
                    )}
                  >
                    <Card
                      url={`/${item.category.slug}/${item.id}`}
                      prodDetail={item.title}
                      price={item.fullPrice}
                      discount={item.discountPercent}
                      discountPrice={item.discountPrice}
                      image={item.images[0] && (item.images[0].largeUrl ?? item.images[0].thumbnailUrl )}
                      prodId={item.id}
                    />
                  </div>
                )
              })}
            </>
          ) : (
            <Col>
              <p>{t`favorites page|favorites empty`}</p>
            </Col>
          )}
        </Row>
      )}
    </Container>
  )
}
