import React from 'react'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons'
import { Row, Col, Container } from '@kogk/common'
import { ValidationMessage, NativeSelect } from '@cmp/site'
import { Controller } from 'react-hook-form'

export default ({
  className,
  postCodes,
  control,
  register,
  errors,
  setValue
}) => {
  return (
    <Container className='p-0'>
      <Row>
        <Col className='d-flex flex-column justify-content-between w-100'>
          <div className={cn('form-input', className)}>
            <fieldset>
              <label>
                Nafn
                <FontAwesomeIcon className='required' icon={faAsterisk} />
                <input
                  ref={register({ required: 'Nafn má ekki vera autt' })}
                  className={cn({ error: errors.name })}
                  name='name'
                  type='text'
                />
                {errors.name && (
                  <ValidationMessage message={errors.name.message} />
                )}
              </label>

              <label>
                Símanúmer
                <FontAwesomeIcon className='required' icon={faAsterisk} />
                <input
                  ref={register({
                    required: 'Símanúmer má ekki vera autt',
                    validate: s => {
                      if (s.replace(/\D/g, '').length < 7) {
                        return 'Símanúmer verður að vera lágmarki 7 tölustafir'
                      }
                    },
                    pattern: {
                      value: /^\+?[\d -]+$/,
                      message: 'Símanúmer er ekki gilt'
                    }
                  })}
                  className={cn({ error: errors.phone })}
                  name='phone'
                  type='text'
                  inputMode='numeric'
                />
                {errors.phone && (
                  <ValidationMessage message={errors.phone.message} />
                )}
              </label>

              <label>
                Netfang
                <FontAwesomeIcon className='required' icon={faAsterisk} />
                <input
                  ref={register({
                    required: 'Netfang má ekki vera autt',
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: 'Netfang er ekki gilt'
                    }
                  })}
                  className={cn({ error: errors.email })}
                  name='email'
                  type='email'
                />
                {errors.email && (
                  <ValidationMessage message={errors.email.message} />
                )}
              </label>

              <label>
                Heimilisfang
                <FontAwesomeIcon className='required' icon={faAsterisk} />
                <input
                  ref={register({
                    required: 'Heimilisfang má ekki vera autt'
                  })}
                  className={cn({ error: errors.street })}
                  type='text'
                  name='street'
                />
                {errors.street && (
                  <ValidationMessage message={errors.street.message} />
                )}
              </label>

              <label>
                Póstnúmer
                <FontAwesomeIcon className='required' icon={faAsterisk} />
                <div>
                  <Controller
                    control={control}
                    name='postCode'
                    render={props => (
                      <NativeSelect
                        ref={register({
                          required: 'Póstnúmer má ekki vera autt'
                        })}
                        name='postCode'
                        hasError={Boolean(errors.postCode)}
                        options={[
                          { label: '', value: '' },
                          ...postCodes.map(({ id, code, town }) => ({
                            value: id,
                            label: `${code} ${town}`
                          }))
                        ]}
                        {...props}
                      />
                    )}
                  />
                </div>
                {errors.postCode && (
                  <ValidationMessage message={errors.postCode.message} />
                )}
              </label>

              <label>
                Athugasemd
                <div
                  contentEditable
                  ref={register('customerComment', {
                    maxLength: {
                      value: 150,
                      message: 'Hámarkslengd er 150'
                    }
                  })}
                  name='customerComment'
                  onInput={e => {
                    setValue('customerComment', e.currentTarget.textContent, {
                      shouldValidate: true
                    })
                  }}
                />
                {errors.customerComment && (
                  <ValidationMessage message={errors.customerComment.message} />
                )}
              </label>
            </fieldset>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
