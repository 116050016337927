import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import { ProductGrid, Filters, MobileFilters } from '@cmp/sections'
import cn from 'classnames'
import useSearch from '@src/hooks/useSearch'
import { useLocation } from '@reach/router'

import styles from './ProductOverview.module.scss'

export default ({ brandId, heading, isSnyrtivara }) => {
  const props = useSearch({ brandId })
  const { pathname } = useLocation()

  const {
    subcategories,
    selectedSubcategories,
    onFilterChange,
    minPriceAvailable,
    maxPriceAvailable,
    minPriceSelected,
    maxPriceSelected,
    order,
    totalProducts
  } = props

  return (
    <>
      <Container>
        <Row className={styles.spacingTop}>
          <Col col={12}>
            <h2 className='mb-3 slider-title'>{heading}</h2>
          </Col>
        </Row>
      </Container>
      <MobileFilters
        isSnyrtivara={isSnyrtivara}
        totalProducts={totalProducts}
        searchFieldProps={{
          onChange: e => onFilterChange('q', e.target.value)
        }}
        priceFieldProps={{
          minPrice: minPriceAvailable,
          maxPrice: maxPriceAvailable,
          currentMin: minPriceSelected,
          currentMax: maxPriceSelected,
          onChangeMin: v => onFilterChange('min', v),
          onChangeMax: v => onFilterChange('max', v)
        }}
        sortFieldProps={{
          selected: order,
          onChange: v => onFilterChange('order', v)
        }}
        subcategoryFieldProps={{
          allItems: subcategories,
          selected: selectedSubcategories,
          onChange: selected => onFilterChange('s', selected)
        }}
      />
      <Filters
        isSnyrtivara={isSnyrtivara}
        searchFieldProps={{
          onChange: e => onFilterChange('q', e.target.value)
        }}
        priceFieldProps={{
          minPrice: minPriceAvailable,
          maxPrice: maxPriceAvailable,
          currentMin: minPriceSelected,
          currentMax: maxPriceSelected,
          onChangeMin: v => onFilterChange('min', v),
          onChangeMax: v => onFilterChange('max', v)
        }}
        sortFieldProps={{
          selected: order,
          onChange: v => onFilterChange('order', v)
        }}
        subcategoryFieldProps={{
          allItems: subcategories,
          selected: selectedSubcategories,
          onChange: selected => onFilterChange('s', selected)
        }}
      />
      {props && (
        <ProductGrid {...props} isSnyrtivara={isSnyrtivara} path={pathname} />
      )}
    </>
  )
}
