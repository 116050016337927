import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import { pluralize } from '@root/src/utils/index'
import { Link } from '@kogk/gatsby-theme-base'
import styles from './ProductGrid.module.scss'
import { Card, Pagination } from '@cmp/site'

export default ({
  path,
  isSnyrtivara,
  currentPage,
  totalPages,
  products,
  totalProducts,
  makeHref
}) => {
  return (
    <Container>
      <Row>
        <Col col={12} className='pb-md-1 pt-3 pt-md-4'>
          {totalProducts !== undefined && (
            <p
              className={cn(
                styles.subHeading,
                'parag parag--x-small opacity--05'
              )}
            >
              {totalProducts} {pluralize(totalProducts, 'vara', 'vörur')}
            </p>
          )}
        </Col>
      </Row>
      <Row className='flex-wrap px-2 px-md-0'>
        {products.length > 0 &&
          products.map(item => {
            return (
              <Card
                key={item.id}
                url={`/${item.category.slug}/${item.id}`}
                prodDetail={item.title}
                price={item.fullPrice}
                discount={item.discountPercent}
                discountPrice={item.discountPrice}
                image={item.images[0]?.largeUrl ?? item.images[0]?.thumbnailUrl}
                prodId={item.id}
                className='px-1 mb-3 mb-lg-4 col-6 col-sm-4 col-md-3 col-lg-2'
              />
            )
          })}
      </Row>
      {totalPages > 0 && (
        <Pagination
          isSnyrtivara={isSnyrtivara}
          currentPage={currentPage}
          totalPages={totalPages}
          renderLink={({ page, ...props }) => (
            <Link
              to={path + makeHref({ page: page === 1 ? undefined : page })}
              {...props}
            />
          )}
        />
      )}
    </Container>
  )
}
