import React, { useEffect, useContext, useState } from 'react'
import cn from 'classnames'
import { useLocation } from '@reach/router'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Row, Col, Container } from '@kogk/common'
import { MainLayout, Button, ButtonLink } from '@cmp/site'
import { CartVisibility } from '@root/src/context/CartVisibility'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import { Link } from '@kogk/gatsby-theme-base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { getLoginPath } from '@root/src/utils/index'

const Login = ({
  onSkipLogin,
}) => {
  const { language } = useLanguage()
  const location = useLocation()
  const { modifyCartVisibility } = useContext(CartVisibility)
  const { modify } = useContext(MenuTypeContext)
  const [agree, setAgree] = useState(false)

  useEffect(() => {
    modifyCartVisibility(false)
    modify('primary')
  }, [])

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <Container className='mt-2 mt-md-0 pt-4 pt-md-6 mb-md-6'>
          <Row className='flex-column align-items-md-center justify-content-center text-md-center'>
            <Col className='col-10 col-md-5 pb-2'>
              <h1 className='blue'>Skráðu þig inn með rafrænum skilríkjum</h1>
            </Col>
            <Col col={{ md: 5, lg: 4 }} className='py-3 d-flex align-items-center px-md-0'>
              <button
                onClick={() => setAgree(!agree)}
                className='no-style d-flex px-0 align-items-center'
              >
                <div className={cn(
                  'd-flex mr-1 checkbox checkbox--border',
                  { 'checkbox--active': agree }
                )}>
                  {agree && (<FontAwesomeIcon icon={faCheck} />)}
                </div>
                <span className='mb-0 article text-left'>
                  Ég hef kynnt mér og samþykki <Link to='/skilmalar'>skilmála</Link>
                </span>
              </button>
            </Col>
            <Col col={{ md: 5, lg: 4 }} className='px-md-0'>
              <ButtonLink
                type='primary'
                disabled={!agree}
                href={getLoginPath(location.href)}
                className='w-100'
                label='Auðkenna'
              />
              {onSkipLogin && (
                <>
                  <div className='separator' />
                  <Button
                    type='light-blue'
                    disabled={!agree}
                    onClick={onSkipLogin}
                    label='Klára sem gestur'
                    className='w-100 mb-3 mb-md-2'
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Login
