import React from 'react'
import { useAuthentication } from '@src/context/Authentication'
import LoadingPage from '@cmp/templates/LoadingPage/LoadingPage'
import Login from '@cmp/templates/Login/Login'
import { useLocation } from '@reach/router'
import { getLoginPath } from '@root/src/utils/index'

export default Comp => {
  return props => {
    const { user, loading } = useAuthentication()
    const location = useLocation()

    if (loading) {
      return <LoadingPage />
    }

    if (!user) {
      // redirect to login page
      return <Login backTo={getLoginPath(location.href)}  />
    }

    return <Comp {...props} />
  }
}
