import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import cn from 'classnames'

import styles from './Verslun.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'

export default () => {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Verslun component</h2>
        </Col>
      </Row>
    </Container>
  )
}
