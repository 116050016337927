import React from 'react'
import { Col, Row, Container } from '@kogk/common'
import cn from 'classnames'
import styles from './StrippedSlider.module.scss'
import Card from '@src/components/site/Card'

export default ({ products, scrollContainer, widthBorder }) => {
  const numberOfProducts = products?.length

  return (
    <Container>
      {numberOfProducts > 0 && (
        <Row>
          <div
            ref={scrollContainer}
            className={cn(
              styles.sliderWrapperInner,
              'col-12 d-flex overflow-auto'
            )}
          >
            {products &&
              products.map(
                (
                  {
                    discountPercent,
                    title,
                    fullPrice,
                    discountPrice,
                    category,
                    productGroupId,
                    images
                  },
                  key
                ) => {
                  const image = images[0]?.largeUrl ?? images[0]?.thumbnailUrl

                  return (
                    <Card
                      key={key}
                      image={image}
                      price={fullPrice}
                      discountPrice={discountPrice}
                      url={`/${category.slug}/${productGroupId}`}
                      prodDetail={title}
                      discount={discountPercent}
                      className={cn(
                        styles.cardWrapper,
                        'col-4 col-lg-2 px-1 mx-0'
                      )}
                      prodId={productGroupId}
                    />
                  )
                }
              )}
          </div>
          {widthBorder && <Col col={12} className={styles.rowBorder} />}
        </Row>
      )}
    </Container>
  )
}
