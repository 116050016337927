import React, { useContext, useState, useCallback } from 'react'
import Button from '@cmp/site/Button'
import { ModalContext } from '@root/src/context/ModalContext'
import { Overview, Details, Synonyms } from './components'
import { useCartState, useCartActions } from '@src/context/Cart'
import usePrescriptionPrice from '@src/hooks/usePrescriptionPrice'
import { useCartVisibility } from '@root/src/context/CartVisibility'

const ModalDrugTemplate = () => {
  const { close: closePrescriptionModal, content } = useContext(ModalContext)
  const { cart } = useCartState()
  const { open: openCartPreview } = useCartVisibility()
  const [quantity, setQuantity] = useState(1)
  const [substituteDrug, setSubstituteDrug] = useState()
  const {
    addPrescriptionToCart,
    loading: isAddingToCart,
    error
  } = useCartActions({
    onAddedToCart: () => {
      closePrescriptionModal()
      openCartPreview()
    }
  })
  const { price, loading: isPriceLoading } = usePrescriptionPrice({
    prescription: content.item,
    userId: content.userId,
    quantity,
    substituteDrug
  })

  const addToCart = useCallback(() => {
    addPrescriptionToCart({
      id: content.item.id,
      productId: substituteDrug && substituteDrug.id,
      userId: content.userId,
      quantity
    })
  })

  /*
   *  visible part of prescription
   *  Overview (default) | Details | Synonyms
   */
  const [visiblePart, setVisiblePart] = useState('Overview')

  const prescriptionParts = {
    Overview,
    Details,
    Synonyms
  }

  const titles = {
    Overview: '',
    Details: content.name || '',
    Synonyms: 'Samheitalyf'
  }

  const Prescription = prescriptionParts[visiblePart]

  if (!Prescription) return null

  return (
    <div className='modal-content'>
      <div className='modal-content__header'>
        <Button
          onClick={() =>
            visiblePart === 'Overview'
              ? setVisiblePart('Details')
              : setVisiblePart('Overview')
          }
          className='p-0 bg-white'
          type='pill'
          icon={visiblePart === 'Overview' ? 'info' : 'arrowLeft'}
        />
        <h5 className='modal-content__header__title'>{titles[visiblePart]}</h5>
        <Button
          className='modal-content__header__close-btn'
          onClick={() => closePrescriptionModal()}
          type='pill'
          icon='close'
        />
      </div>
      <div className='modal-content__content'>
        <Prescription
          content={content}
          description={(substituteDrug || content.item).description}
          onSelectDrug={setSubstituteDrug}
          isPriceLoading={isPriceLoading}
          price={price}
          quantity={quantity}
          onChangeQuantity={setQuantity}
          inventoryCount={(substituteDrug || content.item).inventoryCount}
          addToCart={addToCart}
          isAddingToCart={isAddingToCart}
          setVisiblePart={setVisiblePart}
          error={error}
        />
      </div>
    </div>
  )
}

export default ModalDrugTemplate
