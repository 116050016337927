import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import React, { useContext } from 'react'
import { Primary, Secondary } from './types'

const Navbar = ({ isSnyrtivara }) => {
  const { template } = useContext(MenuTypeContext)

  const types = {
    primary: Primary,
    secondary: Secondary
  }

  const Cmp = types[template.type]

  if (!Cmp) return null

  return (
    <Cmp
      pageTitle={template.title}
      isFrontpage={template.isFrontpage}
      isSnyrtivara={isSnyrtivara}
    />
  )
}

export default Navbar
