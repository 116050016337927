import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStoreAlt } from '@fortawesome/pro-solid-svg-icons'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import cn from 'classnames'

import styles from './PickupDisclaimer.module.scss'

export default ({ color }) => {
  const { t } = useLanguage()

  return (
    <div className={styles.disclaimer}>
      <FontAwesomeIcon icon={faStoreAlt} />
      <p className='parag parag--x-small opacity--08'>
        {t(`disclaimers|store pickup`)}
      </p>
    </div>
  )
}
