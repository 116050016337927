import { GET_PRESCRIPTION_PRICE } from '../data/backend/queries'
import { useQuery } from '@apollo/client'

export default ({ prescription, userId, quantity, substituteDrug }) => {
  const query = useQuery(GET_PRESCRIPTION_PRICE, {
    skip: prescription.inventoryCount === 0,
    variables: {
      id: prescription.id,
      userId,
    }
  })

  if (substituteDrug) {
    return {
      loading: false,
      price: substituteDrug.price * quantity
    }
  }

  return {
    ...query,
    price: query.data && query.data.prescription.price * quantity
  }
}
