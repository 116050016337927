import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { ModalContext } from '@root/src/context/ModalContext'
import Button from '@cmp/site/Button'
import { Link } from '@kogk/gatsby-theme-base'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons'
import cn from 'classnames'
import { formatPriceString, getInsurancePeriod } from '@src/utils'

export const query = graphql`
  query {
    allPrismicInsuranceSteps {
      nodes {
        ...fragmentPrismicInsuranceSteps
      }
    }
  }
`

const findInsuranceScheme = (nodes, scheme) => {
  const exactMatch = nodes.find(n => n.data.scheme?.includes(scheme))
  return (exactMatch ?? nodes.find(n => !n.data.scheme)).data
}

const formatPayment = (step, prev, isLastStep) => {
  return isLastStep
    ? `> ${formatPriceString(prev?.max_payment ?? 0, true)}`
    : [
        formatPriceString(prev?.max_payment ?? 0),
        formatPriceString(step.max_payment, true)
      ].join(' - ')
}

const InsuranceStepTemplate = () => {
  const { close, content: insurance } = useContext(ModalContext)
  const { periodPayment, stepIndex, periodStartDate } = insurance

  const {
    allPrismicInsuranceSteps: { nodes }
  } = useStaticQuery(query)

  const {
    text: { text: text },
    link: { url: link },
    link_text: { text: linkText },
    steps
  } = findInsuranceScheme(nodes, insurance.scheme)

  return (
    <div className='modal-content px-1 pb-2'>
      <div className='d-flex justify-content-end mb-2'>
        <Button
          className='modal-content__header__close-btn'
          onClick={() => close()}
          type='pill'
          icon='closeRegular'
        />
      </div>
      <h1 className='hdln--3 mb-1'>
        Þú hefur greitt{' '}
        <span className='blue'>{formatPriceString(periodPayment, true)}</span> á
        þessu tímabili
      </h1>
      <div>
        <p className='parag--small-fixed-size-2 opacity--08 mb-3'>
          {getInsurancePeriod(periodStartDate)}
        </p>
      </div>
      <table className='rounded-table pb-3'>
        <tr>
          <th>
            <p className='parag--x-small-fixed-size basalt-800 mb-0'>Þrep</p>
          </th>
          <th>
            <p className='parag--x-small-fixed-size basalt-800 mb-0'>
              Lyfjakostnaður á tímabili
            </p>
          </th>
          <th>
            <p className='parag--x-small-fixed-size basalt-800 mb-0'>
              Þinn greiðsluhluti
            </p>
          </th>
        </tr>
        {steps.map((step, key) => (
          <tr
            key={key}
            className={cn('w-100 rounded-table__row', {
              'rounded-table--active': key + 1 === stepIndex
            })}
          >
            <td>
              <p className='text-left'>{key + 1}</p>
            </td>
            <td>
              <p className='text-left'>
                {formatPayment(step, steps[key - 1], !steps[key + 1])}
              </p>
            </td>
            <td>
              <p className='text-left'>{step.percent}%</p>
            </td>
          </tr>
        ))}
      </table>
      <div>
        <p className='parag--small-fixed-size-2 family--display basalt-800 mb-3'>
          {text}
        </p>
        <Link
          to={link}
          className='parag parag--small-fixed-size-2 link--external blue pb-3'
        >
          {linkText}
          <FontAwesomeIcon className='ml-1' icon={faExternalLink} />
        </Link>
      </div>
    </div>
  )
}

export default InsuranceStepTemplate
