import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import { StrippedSlider } from '@cmp/sections'

import styles from './PopularProdSlider.module.scss'

export default ({ heading, products }) => {
  return (
    <div className={cn('pt-3 pt-md-4 mt-md-4 mb-1', styles.sliderWrapper)}>
      <Container>
        <Row>
          <Col className='d-flex justify-content-between pb-2 pb-lg-3' col={12}>
            <h3 className='mb-0 slider-title'>{heading}</h3>
          </Col>
          <StrippedSlider products={products} />
          <Col col={12} className={styles.rowBorder} />
        </Row>
      </Container>
    </div>
  )
}
