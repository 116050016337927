import React from 'react'
import { Loading, Error } from '@cmp/site'

export default ({ query, render }) => {
  if (query.loading) {
    return <Loading />
  }

  if (query.error) {
    return <Error error={query.error} />
  }

  return render(query.data)
}
