import React from 'react'
import { Row, Col, Container, RawHtml, FancyShow } from '@kogk/common'
import Img from 'gatsby-image'
import cn from 'classnames'
import { useGlobalData } from '@src/data/global-data'
import styles from './Feature.module.scss'

const left = { order: { md: 1 } }
const right = { order: { md: 2 }, offset: { lg: 1 } }

const alternateProps = alignment => {
  return alignment === 'left' ? [left, right] : [right, left]
}

const Feature = ({ imageAlignment = 'left', html, image, mobile }) => {
  const { isMobile } = useGlobalData()

  const [first, second] = alternateProps(imageAlignment)
  const img = isMobile ? mobile : image

  const rowClasses = cn('align-items-center d-flex', styles.xSpacingRow)
  const imageClasses = cn('mb-1 mb-md-0 px-0', styles.xSpacingCol)

  return (
    <FancyShow>
      <section className={styles.sectionContainer}>
        <Container>
          <Row className={rowClasses}>
            <Col col={{ md: 6 }} {...first} className={imageClasses}>
              <Img alt={image.alt} fluid={img.fluid} />
            </Col>

            <Col col={{ lg: 5, md: 6 }} className={styles.textCol} {...second}>
              <FancyShow>
                <RawHtml html={html} className='article' />
              </FancyShow>
            </Col>
          </Row>
        </Container>
      </section>
    </FancyShow>
  )
}

export default Feature
