import React, { useContext } from 'react'
import { ProgressBar, Button, Loading, ValidationMessage } from '@cmp/site'
import cn from 'classnames'
import styles from './UserPrescriptions.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/pro-solid-svg-icons'
import { ModalContext } from '@root/src/context/ModalContext'
import { formatSSN } from '@root/src/utils/index'

export default ({ items, user, loading, error }) => {
  const { runModal } = useContext(ModalContext)

  const renderContent = () => {
    if (loading) {
      return <Loading border message='Sæki lyfseðla' />
    }

    if (error) {
      return <ValidationMessage message={error.message} />
    }

    if (items.length === 0) {
      return (
        <p className='parag parag--small'>
          Engir lyfseðlar fundust fyrir {user.name}
        </p>
      )
    }

    return items.map(item => (
      <Button
        key={item.id}
        onClick={() => runModal('drug', { item, userId: user.id })}
        className='product-row'
        icon='angleDown'
        type='blanco'
      >
        <SingleProductRow
          name={item.description}
          type={item.category}
          progress={item.progress}
        />
      </Button>
    ))
  }

  return (
    <div>
      <h2 className='hdln--5 blue d-none d-md-block'>
        <FontAwesomeIcon
          icon={faClipboard}
          className='d-none d-md-inline mr-1'
        />
        Lyfseðlar
      </h2>
      {renderContent()}
    </div>
  )
}

const SingleProductRow = ({ name, type, progress }) => {
  return (
    <div className={cn('flex-lg-row', styles.singleCol, styles.infoWrapper)}>
      <div className={styles.progressBar}>
        <ProgressBar progress={progress} />
      </div>
      <div className={cn(styles.productInfo, 'pl-md-2 pr-3')}>
        <p className='parag--medium'>{name}</p>
        <p className='parag--x-small-fixed-size'>{type}</p>
      </div>
    </div>
  )
}

/*
const TwoProductRow = ({ secondItem, drug }) => {
  return (
    <div className={cn(styles.twoCol, styles.infoWrapper)}>
      <div className={styles.leftInfoCol}>
        <div className={styles.progressBar}>
          <ProgressBar progress={drug.progress} />
        </div>
        <div className={cn(styles.productInfo, 'pr-3 pb-2 pb-lg-0')}>
          <p className='parag--medium'>{drug.name}</p>
          <p className='parag--x-small-fixed-size'>{drug.type}</p>
        </div>
      </div>
      <div className={cn(styles.productInfo, 'pl-lg-3', styles.secondProduct)}>
        <p className='parag--medium'>{secondItem.name}</p>
        <p className='parag--x-small-fixed-size'>{secondItem.type}</p>
      </div>
    </div>
  )
}
*/
