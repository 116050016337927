import React, { useState, useContext } from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow
} from '@kogk/common'
import { SliceWrapper } from '@cmp/site'
import cn from 'classnames'
import { Button } from '@cmp/site'
import { ViewTemplateContext } from '@root/src/context/ViewTemplateContext'
import styles from './FormInfoContainer.module.scss'
import {
  AddressForm,
  CreditCardForm
} from '@cmp/templates/ViewTemplate/Types'
import { useGlobalData } from '@src/data/global-data'
import { navigate } from 'gatsby'

export default ({
  data,
  formTypes,
  icon,
  label
}) => {
  const types = {
    creditCard: CreditCardForm,
    address: AddressForm
  }

  const categories = {
    creditCard: 'Skrá Kortauplýsingar',
    address: 'Skrá heimilisfang'
  }
  const Cmp = types[formTypes]
  const [openForm, setOpenForm] = useState('')
  const { setView } = useContext(ViewTemplateContext)
  const { isMobile } = useGlobalData()

  const changeData = () => {
    if (isMobile) {
      setView(formTypes, {}, categories[formTypes])
      navigate('/viewtemplate')
    } else {
      setOpenForm(formTypes)
    }
  }
  return (
    <>
    {!data || openForm === formTypes ? (
      <>
        {openForm === formTypes ? (
          <Cmp className='d-flex flex-column justify-content-between pb-4 pb-md-0' />
        ) : (
          <Button
            label={label}
            icon={icon}
            lightBlue
            large
            bold
            rounded
            onClick={() => changeData()}
            className={cn(styles.buttonLabels, 'w-100')}
          />
        )}
      </>
    ) : (
      <div className='d-flex justify-content-between align-items-center bordered-wrapper'>
        <div>
          {data.map((item, key) => (
            <p
              key={key}
              className='parag--x-small-fixed-size mb-0 parag--feature-off'
            >
              {item}
            </p>
          ))}
        </div>
        <Button
          label='Breyta'
          pill
          lightBlue
          onClick={() => changeData()}
        />
      </div>
    )}
  </>
  )
}
