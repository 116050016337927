export const parseCardExpiry = s => {
  const match = s.match(/^(?<month>\d\d)\/(?<year>\d{2}|\d{4})$/);
  return match && match.groups;
}

export const validateCardExpiry = s => {
  const match = parseCardExpiry(s);
  if (!match) {
    return 'Gildistími er ekki réttur';
  }

  let { year, month } = match;
  if (year.length === 2) {
    year = 2000 + Number(year);
  }

  const cardDate = new Date(Number(year), Number(month) - 1);

  const now = new Date();
  const minDate = new Date(now.getFullYear(), now.getMonth());

  if (cardDate.getTime() < minDate.getTime()) {
    return 'Gildistími er útrunninn';
  }
}