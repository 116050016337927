import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import RadioButton from '@cmp/site/RadioButton'
import Button from '@cmp/site/Button/Button'
import QueryHandler from '@cmp/site/QueryHandler/QueryHandler'
import { GET_PRESCRIPTION_SUBSTITUTES } from '@src/data/backend/queries'
import { formatPriceString } from '@root/src/utils/index'

const Synonyms = ({
  content: { item, userId },
  setVisiblePart,
  selectedDrug,
  onSelectDrug
}) => {
  const [checked, setChecked] = useState(selectedDrug)

  const query = useQuery(GET_PRESCRIPTION_SUBSTITUTES, {
    variables: {
      id: item.id,
      userId: userId
    }
  })

  return (
    <div className='position-relative h-100'>
      <QueryHandler
        query={query}
        render={({ prescription: { samheitalyf } }) => (
          <>
            {samheitalyf.map(item => (
              <div
                key={item.id}
                className='d-flex align-items-center modal-content__details-row justify-content-between py-2'
              >
                <RadioButton
                  onClick={() => setChecked(item)}
                  disabled={item.inventoryCount <= 0}
                  label={item.description}
                  checked={!!checked && item.id === checked.id}
                  className='h-100 my-1 text-left'
                />
                <p className='parag--small-fixed-size modal-content__synonyms__price'>
                  {item.inventoryCount <= 0 ? (
                    <p className='red'>Uppselt</p>
                  ) : (
                    formatPriceString(item.price, true)
                  )}
                </p>
              </div>
            ))}
            {samheitalyf.length === 0 ? (
              <p className='blue'>
                Því miður fundust engin samheitalyf fyrir þetta lyf
              </p>
            ) : (
              <Button
                primary
                large
                bold
                rounded
                label='Velja lyf'
                className='w-100 mt-2 modal-content__btn'
                onClick={() => {
                  if (checked) {
                    onSelectDrug(checked)
                    setVisiblePart('Overview')
                  }
                }}
              />
            )}
          </>
        )}
      />
    </div>
  )
}

export default Synonyms
