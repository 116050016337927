import React, { useState } from 'react'
import cn from 'classnames'
import Button from '@cmp/site/Button/Button'
import { formatPriceString } from '@root/src/utils/index'

const PriceSliderFilter = ({
  onClick,
  title,
  minPrice,
  maxPrice,
  currentMin,
  currentMax,
  onChangeMin,
  onChangeMax
}) => {
  // decides which button, min or max, is on top of the other
  let [onTop, setOnTop] = useState()

  currentMin = Math.max(minPrice, currentMin)
  currentMax = Math.min(maxPrice, currentMax)

  const range = maxPrice - minPrice
  const minPerc = ((currentMin - minPrice) / range) * 100
  const maxPerc = 100 - ((maxPrice - currentMax) / range) * 100

  // if user hasn't used the sliders yet we decide automatically which one is on
  // top based on its positions
  if (onTop === undefined) {
    onTop = minPerc > 50 ? 'min' : 'max'
  }

  const reset = () => {
    onChangeMin(minPrice)
    onChangeMax(maxPrice)
  }

  const calcValue = e =>
    Math.round(minPrice + (range * parseInt(e.currentTarget.value, 10)) / 100)

  return (
    <div className='price-slider-wrap'>
      <div className='mobile-header d-flex d-md-none align-items-center'>
        <Button
          onClick={onClick}
          icon='arrowLeft'
          type='blanco'
          className='pl-0'
        />
        <h5 className='mb-0 w-100 text-center position-absolute'>{title}</h5>
        <Button
          onClick={() => reset()}
          className='bold'
          label={'Hreinsa'}
          type='mini-ghost'
        />
      </div>
      <div className='d-flex price-display justify-content-between pb-2'>
        <p>{formatPriceString(currentMin, true)}</p>
        <p>{formatPriceString(currentMax, true)}</p>
      </div>
      <div className='position-relative'>
        <span className='price-slider__pseudo-stick' />
        <input
          onChange={e => {
            onTop !== 'min' && setOnTop('min')
            const v = calcValue(e)
            v <= currentMax && onChangeMin(v === minPrice ? undefined : v)
          }}
          className={cn('price-slider', {
            'price-slider__ontop': onTop === 'min'
          })}
          type='range'
          value={minPerc}
        />
        <input
          onChange={e => {
            onTop !== 'max' && setOnTop('max')
            const v = calcValue(e)
            v >= currentMin && onChangeMax(v === maxPrice ? undefined : v)
          }}
          className={cn('price-slider', {
            'price-slider__ontop': onTop === 'max'
          })}
          type='range'
          value={maxPerc}
        />
      </div>
    </div>
  )
}

export default PriceSliderFilter
