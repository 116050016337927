import React from 'react'
import ButtonLink from '@cmp/site/ButtonLink'
import Button from '@cmp/site/Button'
import { navigate } from 'gatsby'

const Secondary = ({ pageTitle = '' }) => {
  return (
    <nav className='navbar navbar--secondary align-items-center justify-content-between'>
      <Button
        onClick={() => navigate(-1)}
        icon='close'
        type='blanco'
        className='navbar__icon-btn'
      />
      <h5 className='mb-0 d-block d-md-none w-100 text-center'>{pageTitle}</h5>
      <ButtonLink
        type='mini-ghost'
        icon='user'
        to='/lyfjagatt'
        className='navbar__user-login'
      />
    </nav>
  )
}

export default Secondary
