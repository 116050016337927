import React from 'react'
import { SearchInputSection, AllarVerslanir, Map, StaffSection } from '@cmp/sections'

const System = ({ programName, options }) => {
  if (programName === 'starfsfolk') {
    return <StaffSection />
  }
  if (programName === 'verslanir') {
    return <AllarVerslanir />
  }
  if (programName === 'map') {
    return <Map lat={options.initialLat} log={options.initialLon} />
  }
  if (programName === 'search input') {
    return (
      <SearchInputSection
        placeholder={options.placeholder}
        className='mb-md-3 bg--blue'
      />
    )
  }

  return <div>unhandled {programName}</div>
}

export default System
