import React, { useState, useContext } from 'react'
import cn from 'classnames'
import { Button } from '@cmp/site'
import {
  Row,
  Col,
  Container
} from '@kogk/common'
import styles from '../MobileFilters.module.scss'
import { ListFilter, PriceSliderFilter } from '../../../sections/Filters/Types'
import useLockBodyScroll from '@src/hooks/useLockBodyScroll'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'

export default ({
  filterOptions,
  title,
  onClick,
  totalProducts,
  backBtnIcon = 'arrowLeft'
}) => {
  const [openFilter, setOpenFilter] = useState()
  const { setShowUserIcon } = useContext(MenuTypeContext)
  const buttonLabel = `Sjá vörur ${totalProducts > 0 ? '(' + totalProducts + ')' : ''}`

  const seeProducts = () => {
    onClick()
  }
  const setFilters = (filter) => {
    setOpenFilter(filter)
  }

  useLockBodyScroll()
  setShowUserIcon(false)

  return (
    <Container className={styles.container}>
      <Row>
        <Col
          col={12}
          className={cn(
            'filter__item d-flex w-100 justify-content-between align-items-center filter__header',
            styles.header
          )}
        >
          <Button
            onClick={() => onClick()}
            icon={backBtnIcon}
            type='blanco'
            className='d-block d-md-none pl-0'
          />
          <h5 className='mb-0 w-100 text-center'>{title}</h5>
        </Col>

        {filterOptions &&
          filterOptions.map((item, i) => {
            const selectedItems = item.filterProps?.selected?.length
              ? `(${item.filterProps.selected?.length})`
              : null

            const Cmp =
              item.typename === 'price' ? PriceSliderFilter : ListFilter

            if (item.filterProps) {
              return (
                <>
                  <Col col={12} key={i}>
                    <button
                      onClick={() => setFilters(item.label)}
                      className={cn(
                        { 'border-bottom': i < filterOptions.length - 1 },
                        'd-flex no-style justify-content-between pt-2 w-100 px-0'
                      )}
                    >
                      <p className='parag--small-fixed-size'>
                        {item.label} {selectedItems && selectedItems}
                      </p>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                  </Col>
                  {openFilter && openFilter === item.label && (
                    <Col col={12} className={styles.filterWrapper}>
                      <Cmp
                        {...item.filterProps}
                        title={item.label}
                        onClick={() => setFilters(null)}
                        bottomSpace
                      />
                    </Col>
                  )}
                </>
              )
            }
          })}
      </Row>
      <Row>
        <Col className={styles.submitBtn} col={12}>
          <Button
            onClick={() => seeProducts()}
            className='w-100'
            label={buttonLabel}
            primary
            bold
            large
          />
        </Col>
      </Row>
    </Container>
  )
}
