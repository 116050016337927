import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import useSearch from '@src/hooks/useSearch'
import { CardWithButton } from '@cmp/site'

import styles from './FeaturedCategories.module.scss'

export default ({ brandId, heading, categoryArr, onClick }) => {
  const { selectedSubcategories, onFilterChange } = useSearch({ brandId })

  return (
    <Container>
      {heading && (
        <Row className={styles.spacingTop}>
          <Col col={12}>
            <h2 className='slider-title mb-2'>{heading}</h2>
          </Col>
        </Row>
      )}
      <Row className={cn(styles.spacingBottom, 'px-2 px-md-0 mt-1')}>
        {categoryArr?.map((i, key) => {
          let filter = i.category_id.text
          if (i.category_id.text.includes(',')){
            filter = i.category_id.text.split(',')
          }
          if(i.product_ids.text !== ''){
            filter = i.product_ids.text.split(',')
          }
          return (
            <div
              className={cn(
                { 'col-md-4': categoryArr.length < 3 },
                styles.flexWrapper,
                'col-6 col-md px-1 mb-2'
              )}
              key={key}
            >
              <CardWithButton
                image={i.image.url}
                title={i.category_name.text}
                onClick={() => {
                  onFilterChange('s', filter), onClick()
                }}
                active={selectedSubcategories.includes(filter)}
              />
            </div>
          )
        })}
      </Row>
    </Container>
  )
}
