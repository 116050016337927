import React from 'react'
import cn from 'classnames'
import luhn from 'luhn'
import { ValidationMessage } from '@cmp/site'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Container } from '@kogk/common'
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons'
import { validateCardExpiry } from '@root/src/utils/form'

const handleCardNumberChange = (event) => {
  let value = event.target.value.replace(/\D/g, ""); // Remove non-digits
  let formattedValue = "";

  for (let i = 0; i < value.length; i++) {
    if (i > 0 && i % 4 === 0) {
      formattedValue += "-";
    }
    formattedValue += value[i];
  }

  event.target.value = formattedValue.substring(0, 19);
};


const handleExpiryDateChange = (event) => {
  let value = event.target.value.replace(/\D/g, ""); // Remove non-digits
  let formattedValue = "";

  for (let i = 0; i < value.length; i++) {
    if (i === 2) {
      formattedValue += "/";
    }
    formattedValue += value[i];
  }

  event.target.value = formattedValue.substring(0, 7);
};

export default ({ className, register, errors, skipValidation = false }) => {
  return (
    <Container className='p-0'>
      <Row className='justify-content-center'>
        <Col className='w-100'>
          <div className={cn('form-input form-input__card-info', className)}>
            <fieldset>
              <label className='card-input'>
                Kortanúmer
                <div className='position-relative'>
                  <FontAwesomeIcon icon={faCreditCard} />
                  <input
                    ref={register({
                      required: !skipValidation ? 'Kortanúmer má ekki vera autt' : false,
                      validate: s => {
                        if(skipValidation) return
                        s = s.replace(/[ -]/g, '')
                        if (s.length !== 16 || !luhn.validate(s)) {
                          return 'Kortanúmer er ekki gilt'
                        }
                      }
                    })}
                    className={cn({ error: errors.cardNumber })}
                    name='cardNumber'
                    type='text'
                    inputMode='numeric'
                    onChange={handleCardNumberChange}
                  />
                </div>
                {errors.cardNumber && (
                  <ValidationMessage message={errors.cardNumber.message} />
                )}
              </label>

              <div className='d-flex'>
                <label className='mr-1'>
                  Rennur út
                  <input
                    ref={register({
                      required:  !skipValidation ? 'Gildistími má ekki vera tómur' : false,
                      validate: (s) => {
                        if(skipValidation) return
                        return validateCardExpiry(s)
                      }
                    })}
                    className={cn({ error: errors.cardExpiry })}
                    name='cardExpiry'
                    type='text'
                    inputMode='numeric'
                    placeholder='MM / ÁÁ'
                    onChange={handleExpiryDateChange}
                  />
                  {errors.cardExpiry && (
                    <ValidationMessage message={errors.cardExpiry.message} />
                  )}
                </label>

                <label className='ml-1'>
                  CVV
                  <input
                    ref={register({
                      required: !skipValidation ? 'Öryggisnúmer má ekki vera autt' : false,
                      pattern: {
                        value: /^\d{3,4}$/,
                        message: 'Öryggisnúmer verður að vera 3-4 tölustafir'
                      }
                    })}
                    className={cn({ error: errors.cvc })}
                    pattern='\d*'
                    maxLength='4'
                    name='cvc'
                    type='tel'
                    inputMode='numeric'
                  />
                  {errors.cvc && (
                    <ValidationMessage message={errors.cvc.message} />
                  )}
                </label>
              </div>
            </fieldset>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
