import React from 'react'
import ProgressBar from '@cmp/site/ProgressBar'
import { formatDate, pluralize } from '@src/utils'

const Details = ({ content: { item } }) => {
  const used = item.withdrawsOriginal - item.withdrawsRemaining

  return (
    <>
      <div className='modal-content__details-row'>
        <p className='parag--small-fixed-size opacity--08'>Læknir</p>
        <p className='parag--medium mb-1 pb-2'>{item.issuerName}</p>
      </div>

      <div className='modal-content__details-row mb-1 py-2'>
        <p className='parag--small-fixed-size opacity--08 mt-1'>Fyrirmæli</p>
        <p className='parag--medium mb-1'>{item.instructions}</p>
      </div>

      <div className='modal-content__details-row mb-1 py-2 d-flex align-items-center justify-content-between'>
        <div className='w-50'>
          <p className='parag--small-fixed-size opacity--08'>Afgreiðslur</p>
          <p className='parag--medium mb-1'>
            {item.type === 'LYFSEDILL' ? (
              <>
                Þú hefur leyst út {used} afgreiðslu{pluralize(used, '', 'r')}{' '}
                af {item.withdrawsOriginal}{' '}
                {pluralize(item.pkgQtyOriginal, 'pakka', 'pökkum')} fyrir þennan
                lyfseðil
              </>
            ) : (
              <>Þú átt {item.withdrawsRemaining} pakka eftir</>
            )}
          </p>
        </div>
        {item.type === 'LYFSEDILL' && (
          <div className='rotate-svg'>
            <ProgressBar size={47} strokeWidth={2} progress={item.progress} />
          </div>
        )}
      </div>

      <div className='d-flex pt-2'>
        <div className='modal-content__details-cell'>
          <p className='parag--small-fixed-size opacity--08'>Útgáfudagur</p>
          <p className='parag--medium mb-0'>{formatDate(item.createdAt)}</p>
        </div>
        <div className='d-flex modal-content__details-cell'>
          <p className='parag--small-fixed-size opacity--08'>
            Gildir til og með
          </p>
          <p className='parag--medium mb-0'>{formatDate(item.lastValidDate)}</p>
        </div>
      </div>
    </>
  )
}

export default Details
