import React from 'react'
import styles from './MenuMobile.module.scss'
import { Container, Row, Col } from '@kogk/common'
import { Link } from '@kogk/gatsby-theme-base'
import cn from 'classnames'
import { ButtonLink } from '@cmp/site'
import useLockBodyScroll from '@src/hooks/useLockBodyScroll'
import { useGlobalData } from '@src/data/global-data'

export default ({ menu, className, isSnyrtivara }) => {
  useLockBodyScroll()
  const { subMenu } = useGlobalData()
  const classes = cn(styles.container, className, {
    [styles.snyrtivara]: isSnyrtivara,
    'black-theme': isSnyrtivara
  })

  return (
    <Container fluid className={classes}>
      <Row className='justify-content-between'>
        <Col col={12} className='d-flex flex-column pb-2 mb-1'>
          {menu &&
            menu.map(({ url, title }, i) => {
              return (
                <Link to={url} className='link link--nav-main pb-2' key={i}>
                  {title}
                </Link>
              )
            })}
          <div className='d-flex flex-column pt-2'>
            {subMenu && (
              subMenu.map(({title, url}, key) => {
                return (
                  <Link key={key} to={url} className='link link--nav-sub'>
                    {title}
                  </Link>
                )
              })
            )}
          </div>
        </Col>
        <Col col={12} className={styles.bottomBtn}>
          <ButtonLink
            to='/lyfjagatt'
            icon='user'
            label='Lyfseðlarnir þínir'
            className='w-100'
            type='ghost'
          />
        </Col>
      </Row>
    </Container>
  )
}
