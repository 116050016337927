import React from 'react'
import { Container, Row, Col } from '@kogk/common'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { graphql, useStaticQuery } from 'gatsby'
import styles from './Footer.module.scss'
import { Link } from '@kogk/gatsby-theme-base'
import { useGlobalData } from '@src/data/global-data'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import logoSrc from '@src/assets/logos/LH-text-logo.svg'

const query = graphql`
  query {
    allPrismicFooter {
      nodes {
        ...fragmentPrismicFooter
      }
    }
  }
`

const Footer = ({ isSnyrtivara }) => {
  const { t } = useLanguage()
  const footerData = useStaticQuery(query)
  const data = footerData.allPrismicFooter.nodes[0].data
  const {
    string_1: { text: title },
    string_2: { text: ssn },
    string_3: { text: address },
    verslun_heiti: { text: verslunHeiti },
    link: { url: link },
    link_text: { text: linkText },
    phone: { text: phone },
    email: { text: email }
  } = data
  const { opnunar_timar: opnunarTimar } = data.verslun.document.data

  return (
    <footer className={cn(styles.container, { [styles.black]: isSnyrtivara })}>
      <Container>
        <Row className='w-lg-100 justify-content-between'>
          <Col
            col={{ xs: 12, md: 2 }}
            className='d-flex flex-column order-2 order-md-1 pb-2 pb-md-0'
          >
            {data.page_links.map((item, key) => {
              return (
                <Link
                  key={key}
                  to={item.link.url}
                  className={styles.footerLink}
                >
                  {item.link_name.text}
                </Link>
              )
            })}
          </Col>

          <Col
            col={{ xs: 12, md: 3 }}
            className={cn('order-1 order-md-2', styles.verslunWrapper)}
          >
            <h2 className='hdln--4 white text-center'>{verslunHeiti}</h2>
            {opnunarTimar &&
              opnunarTimar.map((item, key) => {
                return (
                  <div key={key} className='d-flex justify-content-between'>
                    <p
                      className={cn(
                        styles.bottomLinks,
                        'parag--x-small-fixed-size mb-1'
                      )}
                    >
                      {item.dagur.text}
                    </p>
                    <p className={cn('parag--small-fixed-size white mb-1')}>
                      {item.timi.text}
                    </p>
                  </div>
                )
              })}
            <Link
              to={link}
              className={cn('parag--medium white text-center mt-1')}
            >
              {linkText}
            </Link>
          </Col>

          <Col
            col={{ xs: 12, md: 2 }}
            className='d-md-flex justify-content-between align-items-end pr-0 flex-column order-3'
          >
            <Link className='d-none d-md-block' to='/'>
              <img className={styles.footerLogo} src={logoSrc} />
            </Link>
            <div>
              <Link className={styles.contactLinks} to={`tel:${phone}`}>
                {phone}
              </Link>
              <Link className={styles.contactLinks} to={`mailto:${email}`}>
                {email}
              </Link>
            </div>
          </Col>
        </Row>

        <Row className='w-100'>
          <div className={cn('mb-3', styles.hBorder)} />

          <Col
            col={{ md: 10 }}
            className={cn(
              styles.bottomLinks,
              'flex-column-reverse flex-md-row flex-md-row parag--x-small-fixed-size'
            )}
          >
            <span className='d-flex flex-column flex-md-row'>
              <p className={cn('mr-md-3 pr-md-1 mb-0')}>{title}</p>
              <p className={cn('mr-md-3 pr-md-1 mb-0')}>{ssn}</p>
              <p className='mb-0'>{address}</p>
            </span>

            <div className={cn('d-md-block d-none', styles.vBorder)} />
            <div
              className={cn('d-block d-md-none mx-0 mb-3', styles.hBorder)}
            />

            <Link
              to={t(`footer|skilmalar-link`)}
              className={cn(styles.contactLinks, 'mr-md-3 pr-md-1 pb-0')}
            >
              {t(`footer|skilmalar-text`)}
            </Link>
            <Link
              className={cn('pb-0', styles.contactLinks)}
              to={t(`footer|personuverndarstefna-link`)}
            >
              {t(`footer|personuverndarstefna-text`)}
            </Link>
          </Col>

          <Col
            col={{ md: 2 }}
            className={cn(styles.smLinks, 'd-flex justify-content-end')}
          >
            <Link to={t(`footer|instagram`)} className='mr-2'>
              <FontAwesomeIcon
                className=''
                size='lg'
                icon={faInstagram}
                color='white'
              />
            </Link>
            <Link to={t(`footer|facebook`)}>
              <FontAwesomeIcon
                className=''
                size='lg'
                icon={faFacebook}
                color='white'
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
