import React, { useState, useRef } from 'react'
import cn from 'classnames'
import { formatPriceString } from '@root/src/utils/index'
import styles from './CartSummary.module.scss'
import { ValidationMessage } from '@cmp/site'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faSpinner } from '@fortawesome/pro-light-svg-icons'
import { useCartActions, useCartState } from '@root/src/context/Cart'

export default ({ className, shippingCost = 0 }) => {
  const [dirtyInput, setDirtyInput] = useState(false)
  const btnEl = useRef(null)
  const inputEl = useRef(null)
  const { addPromoCode, loading } = useCartActions()
  const {
    cart: { totalPrice, totalDiscount, promoCode, promoCodeDiscount }
  } = useCartState()
  const showBreakdown = shippingCost > 0 || !!promoCode || !!totalDiscount
  const [userInput, setUserInput] = useState(promoCode)
  const [response, setResponse] = useState()
  const handleInput = input => {
    setUserInput(input)
    setDirtyInput(input.length > 0)
  }

  const handleOnEnter = userInput => {
    btnEl.current.focus()
    validatePromoCode(userInput)
  }

  const validatePromoCode = inputString => {
    setResponse()
    addPromoCode(inputString)
      .then(r => setResponse(r?.data?.addToCart))
      .catch(setResponse)
  }

  const resetPromoCode = () => {
    setUserInput('')
    setDirtyInput(false)
    addPromoCode('')
  }

  return (
    <div className={cn(className, 'd-flex flex-column')}>
      {showBreakdown && (
        <div className={cn(styles.subTotal, 'order-2 order-lg-1')}>
          <div className='d-flex justify-content-between'>
            <p className='price-label pb-1'>Karfa</p>
            <p className='price-tag price-tag--small'>
              {formatPriceString(totalPrice + totalDiscount, true)}
            </p>
          </div>
          {promoCode && (
            <>
              <div className='d-flex justify-content-between'>
                <p className='price-label'>Afsláttarkóði</p>
                <p className='price-tag price-tag--small'>
                  -{formatPriceString(promoCodeDiscount, true)}
                </p>
              </div>
              <div className='d-flex align-items-center pb-1'>
                <p className='price-label price-label--small'>{promoCode}</p>
                <button
                  onClick={() => resetPromoCode('')}
                  className='btn removeGenericButtonStyles price-label py-0'
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            </>
          )}
          {totalDiscount > promoCodeDiscount && (
            <div className='d-flex justify-content-between'>
              <p className='price-label'>
                {promoCode ? 'Annar afsláttur' : 'Afsláttur'}
              </p>
              <p className='price-tag price-tag--small'>
                -{formatPriceString(totalDiscount - promoCodeDiscount, true)}
              </p>
            </div>
          )}
          {shippingCost > 0 && (
            <div className='d-flex justify-content-between'>
              <p className='price-label'>Sendingarkostnaður</p>
              <p className='price-tag price-tag--small'>
                {formatPriceString(shippingCost, true)}
              </p>
            </div>
          )}
        </div>
      )}

      <div className={cn('order-3 order-lg-2', styles.subTotal)}>
        <div className={styles.promoCode}>
          <p className='price-tag price-tag--small'>Samtals</p>
          <p className='price-tag price-tag--small'>
            {formatPriceString(totalPrice + shippingCost, true)}
          </p>
        </div>
      </div>
      <div
        className={cn(styles.inputContainer, 'py-2 py-lg-3 order-1 order-lg-3')}
      >
        <h5 className='blue'>Afsláttarkóði</h5>
        <div className={cn(styles.formContainer, 'd-flex form-input')}>
          <label className={cn('my-0 mr-1 mr-lg-2', styles.input)}>
            <input
              ref={inputEl}
              className={cn('h-100 m-0')}
              placeholder='Sláðu inn kóða'
              value={userInput}
              type='text'
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  handleOnEnter(userInput)
                }
              }}
              onChange={e => handleInput(e.target.value)}
            />
          </label>
          <button
            ref={btnEl}
            label='Virkja'
            type='submit'
            onClick={() => validatePromoCode(userInput)}
            disabled={!dirtyInput || promoCode}
            className={cn(
              styles.btn,
              'the-button the-button--small the-button__ghost parag--medium-2 blue'
            )}
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Virkja'}
          </button>
        </div>
        {response?.message && <ValidationMessage message={response.message} />}
      </div>
    </div>
  )
}
