import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHospital } from '@fortawesome/pro-solid-svg-icons'
import { Button, Loading, ValidationMessage } from '@cmp/site'
import { ModalContext } from '@root/src/context/ModalContext'
import { getInsurancePeriod } from '@root/src/utils'

export default ({ insuranceStatus, hasData, loading, error }) => {
  const { runModal } = useContext(ModalContext)
  const { periodStartDate, stepIndex, stepPercent } = insuranceStatus ?? {}

  const renderContent = () => {
    if (loading || !hasData) {
      return <Loading border bolder message='Sæki greiðsluþrep' />
    }

    if (error) {
      return <ValidationMessage message='Villa kom upp' />
    }

    return (
      <Button
        className='product-row'
        icon='angleDown'
        type='blanco'
        onClick={() => runModal('insuranceStep', { ...insuranceStatus })}
      >
        <div className='text-left'>
          <p className='parag--medium mb-1'>
            Þú ert í greiðsluþrepi {stepIndex} og greiðir því
            <span className='hdln--5 blue'>
              {' '}
              {stepIndex === 3 ? '7.5' : stepPercent}%{' '}
            </span>
            af lyfjakostnaði
          </p>
          <p className='parag--x-small-fixed-size opacity--06'>
            {getInsurancePeriod(periodStartDate)}
          </p>
        </div>
      </Button>
    )
  }
  return (
    <div>
      <h2 className='hdln--5 blue'>
        <FontAwesomeIcon
          className='d-none d-md-inline mr-1'
          icon={faHospital}
        />
        Sjúkratryggingar
      </h2>
      {renderContent()}
    </div>
  )
}
