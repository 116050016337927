import React, { useContext } from 'react'
import { OrderDetails } from '@cmp/templates/ViewTemplate/Types'
import { ModalContext } from '@root/src/context/ModalContext'
import Button from '@cmp/site/Button'

const OrderModalTemplate = () => {
  const { close } = useContext(ModalContext)
  return (
    <div className='modal-content'>
      <div className='modal-content__header'>
        <h1 className='hdln--5 mb-0 modal-content__header__centered-title'>
          Pöntun
        </h1>
        <Button
          className='modal-content__header__close-btn'
          onClick={() => close()}
          type='pill'
          icon='close'
        />
      </div>
      <div className='modal-content__content mt-1'>
        <OrderDetails />
      </div>
    </div>
  )
}

export default OrderModalTemplate
