import React from 'react'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { ButtonLink } from '@cmp/site'
import { useSlideScroll } from '@src/hooks/useSlideScroll'
import styles from './FeaturedBrands.module.scss'

const ButtonSlider = ({ brandLinkArray, scrollContainer }) => {
  return (
    <Container className='px-0'>
      <Row>
        <div
          ref={scrollContainer}
          className={cn(
            styles.sliderWrapperInner,
            'col-lg-12 d-flex overflow-auto'
          )}
        >
          {brandLinkArray.map((brandItem, key) => {
            return (
              <div
                key={key}
                className='button-slider__slider-card-wrapper col-6 col-lg-3 px-1'
              >
                <ButtonLink to={brandItem.link.url} type='light-blue'>
                  <img
                    src={brandItem.logo.url}
                    className='button-slider__image w-100'
                  />
                </ButtonLink>
              </div>
            )
          })}
        </div>
      </Row>
    </Container>
  )
}

export default ({ heading, brand_link_slider: brandLinks }) => {
  if (brandLinks) {
    const { scrollContainer, showLeftArrow, scrollItems, showRightArrow } =
      useSlideScroll(brandLinks.length, 4)
    const arrowStyles = cn(
      styles.arrowButton,
      'no-style d-none d-lg-flex align-items-center arrowBtn px-4'
    )

    return (
      <div className='pt-1 py-md-3 my-md-3 px-0'>
        <Container className='px-0'>
          <Row>
            <Col col={12}>
              <h2 className='slider-title pb-2'>{heading.text}</h2>
            </Col>
          </Row>
        </Container>
        <div className={cn('d-block d-lg-flex pb-3', styles.containerWrapper)}>
          <button
            disabled={!showLeftArrow}
            onClick={() => scrollItems(-1)}
            className={cn('justify-content-start', arrowStyles)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <ButtonSlider
            brandLinkArray={brandLinks}
            scrollContainer={scrollContainer}
          />
          <button
            disabled={!showRightArrow}
            onClick={() => scrollItems(1)}
            className={cn(arrowStyles, 'justify-content-end')}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    )
  } else return <></>
}
