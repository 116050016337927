import React from 'react'
import style from './Card.module.scss'
import cn from 'classnames'
import { Link } from '@kogk/gatsby-theme-base'
import { formatPriceString } from '@src/utils'
import productFallbackImg from '@src/assets/logos/LH-logo-trans.png'
import { FavoriteBtn } from '@cmp/site'

const Card = ({
  url,
  prodDetail,
  image,
  price,
  discount,
  discountPrice = price,
  prodId,
  className
}) => {
  return (
    <div className={cn(className, style.card)}>
      <FavoriteBtn id={prodId} className={style.favoriteBtn} />

      <Link to={url}>
        {discount > 0 && (
          <span className={style.discountLabel}>-{discount}%</span>
        )}

        <div className={style.imageWrapper}>
          {image ? (
            <img
              src={image}
              alt={prodDetail}
              loading='lazy'
              className={style.image}
            />
          ) : (
            <div className={style.placeholder}>
              <div style={{ backgroundImage: `url(${productFallbackImg})` }} />
            </div>
          )}
        </div>
        <div className='prod-title pt-1 opacity--08'>{prodDetail}</div>
        <div className='d-flex flex-column flex-md-row'>
          {discount > 0 && (
            <p className={cn(style.price, 'price-tag price-tag--striked')}>
              {formatPriceString(price, true)}
            </p>
          )}
          <p
            className={cn('price-tag', { 'price-tag--discount': discount > 0 })}
          >
            {formatPriceString(discountPrice, true)}
          </p>
        </div>
      </Link>
    </div>
  )
}

export default Card
