import React, { useState } from 'react'
import cn from 'classnames'
import styles from './FilterEditor.module.scss'
import { SortByFilter } from '@src/components/sections/Filters/Types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import RadioButton from '@cmp/site/RadioButton'

const sortingOptions = [
  { label: 'Nýjasta', value: 'DATE_DESC' },
  { label: 'Verð: hæsta', value: 'PRICE_DESC' },
  { label: 'Verð: lægsta', value: 'PRICE_ASC' }
]

export default ({
  sortFieldProps = {}
}) => {
  const [open, setIsOpen] = useState(false)

  const handleOpen = (open) => {
    if (open === true) {
      document.body.style.overflow = 'hidden'
    } else if (open === false) {
      document.body.style.overflow = 'visible'
    }

    setIsOpen(open)
  }

  return (
    <>
      <button
        onClick={() => handleOpen(!open)}
        className={cn(
          'no-style eyebrow col-6 d-flex justify-content-center align-items-center',
          styles.button
        )}>
        Raða eftir
        <FontAwesomeIcon
          className='ml-1'
          icon={faAngleDown}
        />
      </button>
      <div
        className={cn(styles.dropdown, {
          [styles.open]: open
        })}
      >
        <SortByFilter
          {...sortFieldProps}
          options={sortingOptions}
          className='filter__sortByMobile'
        />
        <button className={styles.filterClickZone} onClick={() => handleOpen(false)} />
      </div>
    </>
  )
}
