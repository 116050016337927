import React, { useContext } from 'react'
import { ModalContext } from '@root/src/context/ModalContext'
import useLockBodyScroll from '@src/hooks/useLockBodyScroll'
import { Drug, Order, InsuranceStep } from './templates'
import { Button } from '../index'

const Content = ({ templateType: t }) => {
  useLockBodyScroll()

  // add templates here
  const templates = {
    drug: Drug,
    order: Order,
    insuranceStep: InsuranceStep
  }

  const Cmp = templates[t]

  if (!Cmp)
    return <p>{`Modal template type provided: "${t}" was not found.`}</p>

  return <Cmp />
}

const Modal = () => {
  const { isOpen, close, templateType } = useContext(ModalContext)

  if (isOpen) {
    return (
      <>
        <div className='modal'>
          <Content templateType={templateType} />
        </div>
        <button onClick={() => close()} className='modal-click-zone no-style' />
      </>
    )
  } else return null
}

export default Modal
