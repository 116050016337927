import React, { useState, useContext } from 'react'
import { Button } from '@cmp/site'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faReceipt,
  faChevronRight,
  faChevronUp
} from '@fortawesome/pro-solid-svg-icons'
import styles from './UserOrders.module.scss'
import { useGlobalData } from '@src/data/global-data'
import { navigate } from 'gatsby'
import { ModalContext } from '@root/src/context/ModalContext'
import { ViewTemplateContext } from '@root/src/context/ViewTemplateContext'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'

export default ({ items, category = 'Pöntun' }) => {
  const [collapsed, setCollapsed] = useState(true)
  const containerClasses = cn(styles.container, {
    [styles.collapsed]: collapsed
  })
  const { isMobile } = useGlobalData()
  const { runModal } = useContext(ModalContext)
  const { setView } = useContext(ViewTemplateContext)
  const { modify } = useContext(MenuTypeContext)

  const viewOrder = item => {
    setView('order', item, category)

    if (isMobile) {
      navigate('/viewtemplate')
      modify('secondary', category)
    } else {
      runModal('order', item)
    }
  }

  return (
    <div>
      <h4 className='pt-3 parag--medium blue mb-0 mb-md-2'>
        <FontAwesomeIcon icon={faReceipt} className='mr-1' />
        Pantanir
      </h4>

      <div className={containerClasses}>
        {items &&
          items.map((item, key) => {
            const productOverflow =
              item.products.length > 2 ? item.products.length - 2 : null

            return (
              <Button
                key={key}
                white
                onClick={() => viewOrder(item)}
                type='ghost'
                className={cn('no-style', styles.btnRow)}
              >
                <div className={styles.orderDetails}>
                  <p className={styles.boldText}>{item.orderNo}</p>
                  <p className={styles.date}>{item.date}</p>
                </div>

                <div className='d-flex'>
                  {item.products.slice(0, 2).map((item, key) => {
                    return (
                      <img
                        className={styles.thumbnail}
                        key={key}
                        src={item.image}
                      />
                    )
                  })}

                  {productOverflow && (
                    <div
                      className={cn(styles.productCounter, styles.thumbnail)}
                    >
                      +{productOverflow}
                    </div>
                  )}
                </div>

                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            )
          })}
        <div className={styles.collapseButtonWrapper}>
          <Button
            large
            white
            onClick={() => setCollapsed(!collapsed)}
            className={styles.collapseButton}
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </Button>
        </div>
      </div>
    </div>
  )
}
